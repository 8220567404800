import { useAppStoreHook } from "@/store/modules/app"
import router from '@/router'
const { getIndexType, switchIndexType } = useAppStoreHook()
function to(type, data) {
  return router[type](typeof data === 'string' ? data : {
    ...data,
    name: getIndexType() + data.name
  })
}
export function useRouterHook() {
  
  const push = function(data: Object | string) {
    return to('push', data)
  }
  const replace = function(data: Object | string) {
    return to('replace', data)
  }
  return { getIndexType, switchIndexType, push, replace }
}
