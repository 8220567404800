<template>
  <div>
    <div class="message other km_aiops">
      <img class="logopng" src="~@/assets/img/layout/logo.png" alt="">
      <div class="content-Area">
      <!-- <div
        v-if="
          messageData.text == '思考中..' &&
          messageData.Arraytext &&
          messageData.Arraytext.length == 0
        "
      > -->
            <div
        v-if="
          messageData.text == '思考中..' 
        "
      >
        <span style="--i: 1" :class="{ thinkrun: turnanimation }">思</span>
        <span style="--i: 2" :class="{ thinkrun: turnanimation }">考</span>
        <span style="--i: 3" :class="{ thinkrun: turnanimation }">中</span>
        <span style="--i: 4" :class="{ thinkrun: turnanimation }">.</span>
        <span style="--i: 5" :class="{ thinkrun: turnanimation }">.</span>
        <span style="--i: 6" :class="{ thinkrun: turnanimation }">.</span>
      </div>
      <div class="flow" v-else>
        <div class="km_aiopsText">
          <div class="text">
            <div class="km_aiopsText">
             <div class="thinkingtext" v-html="messageData.text.replace(/\n/g, '<br>').trim()"></div>
            </div>
          </div>
        </div>
        <div class="process course" v-show="!process">
          <div class="left-tool">
            <span class="left-processbgitem" v-show="turnanimation && historyindex == loadingindex && routetog"><svg-icon class="img" name="stop-generating" size="14"></svg-icon><span class="buttonText" @click="stopGenerating">停止生成</span></span>
             <span class="left-processbgitem" v-show="lshowAgain && historyindex == loadingindex && routetog"><svg-icon class="img" name="redo" size="14"></svg-icon><span class="buttonText" @click="reload(messageData)">重新生成</span></span>
             <span class="left-processbgitem"><svg-icon class="img" name="share" size="14"></svg-icon><span class="buttonText">分享</span></span>
             <span class="left-processbgitem"><svg-icon class="img" name="copy" size="14"></svg-icon><span class="buttonText">复制</span></span>
          </div>
          <span class="processbgitem" @click="openfunction(messageData)"
            >
            思考过程
          </span>
        </div>
        <div v-show="process" class="processbg thinking">
              <span
                v-html="processText(messageData.Arraytext)"
              ></span>
          <img
            :src="retractImg"
            alt=""
            class="retract-img participants"
            @click="process = false"
          />
        </div>
      </div>
       </div>
    </div>
  </div>
</template>
<script>
import robotImg from "@/assets/img/robot.png";
import loadingImg from "@/assets/img/loading.png";
import retractImg from "@/assets/img/retract.png";
import retryImg from "@/assets/img/retry.png";
export default {
  props: {
    messageData: {
      type: Object,
      required: true,
    },
    messagekey: {
      type: String,
      required: true,
    },
    messageAnmation: {
      type: Boolean,
    },
    loadingvalue: {
      type: String,
    },
    historyList:{
      type: String,
      required: true,
    }
  },
  data() {
    return {
      thinking: "",
      robotImg: robotImg,
      loadingImg: loadingImg,
      retractImg: retractImg,
      retryImg:retryImg,
      turnanimation: true,
      process: false, //思考过程展开
      historyindex: "",
      loadingindex: "",
      lshowAgain:false,
      routetog:true,
    };
  },
  created() {
  },
  mounted() {
    if(this.$route.path=='/researchChat'){
      this.routetog=false;
    }
  },
  watch: {
    messageData: {
      deep: true,
      handler(newData) {
    if(this.$route.path=='/researchChat'){
      this.routetog=false;
    }
        if (newData.text == "思考中..") {
          this.turnanimation = true;
        }
        console.log(this.turnanimation, this.historyindex, this.loadingindex);
      },
    },
    messagekey(newData) {
      this.historyindex = newData.split("_")[0];
    },
    messageAnmation: {
      deep: true,
      handler(newData) {
        this.turnanimation = newData;
      },
    },
    loadingvalue: {
      deep: true,
      handler(newData) {
        this.loadingindex = newData;
        console.log(this.loadingindex);
      },
    },
    historyList:{
      deep: true,
      handler(newData) {
        var index = newData.findIndex(
          (newData) => newData.id == this.messageData.id
        );
        if(index+1 ==newData.length){
          this.lshowAgain=true
        }else{
          this.lshowAgain=false
        }
      },
    },
    // $route(to, from) {
    //   console.log(to, from)
    //   console.log(`Route changed from ${from.path} to ${to.path}`);
    //   // 在这里添加你需要执行的逻辑
    // }
  },
  methods: {
    updateChart(data) {
      // console.log(data, "通用");
    },
    openfunction(data) {
      console.log(data);
      this.process = true;
      let sharedData = {
        launch: "",
      };
      this.$emit("updateShare", sharedData);
    },
  processText(arrayText) {
    let result = "";

    arrayText.forEach((item, index) => {
        let textItem = item.replace(/\n/g, '<br>').trim();

        if (index > 0) {
            if (!result.endsWith('<br>') && !textItem.startsWith('<br>')) {
                result += textItem;
            } else {
                result += " " + textItem;
            }
        } else {
            result += textItem;
        }
    });

    return result;
  },
    reload(data){
      this.$emit("reload", data);
    },
        stopGenerating(){
      this.$emit("stopGenerating", true);
    },
      shouldShowProcess() {
      const currentPath = this.$route.path;
      return !this.process && currentPath !== '/researchChat';
    },
}

};
</script>
<style lang="less" scoped>
@import "@/assets/css/common.less";
.thinkrun {
  display: inline-block;
  animation: textrun 1.5s ease-in-out infinite;
  animation-delay: calc(0.1s * var(--i));
}
.participants {
  width: 20px;
  height: 20px;
  background-size: cover;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}
.reload {
  background-size: cover;
  vertical-align: middle;
  cursor: pointer;
  display: inline-block;
  float: right;
  margin-left: 5px;
  margin-top: 3px;
  width: 18px;
  height: 18px;
}
.message-time {
  margin-left: 6px;
  margin-bottom: 10px;
  font-size: 13px;
}
.message.other {
  width: 98%;
  margin-bottom: 10px;
  float: left;
  display: flex;
  .logopng{
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  .content-Area{
    flex: 1;
    background-color: #fff;
    padding: 10px 20px;
    font-family: PingFangSC-Regular;
    border-radius: 4px;
  }
  .km_aiopsText {
    .text {
      background: @text-bgcolor;
      color: #1F1F1F;
      font-size: 14px;
      border-radius: 4px;
      padding: 10px 20px;
      .operate {
        text-align: right;
      }
    }
  }
  .other.km_aiops {
    .flow {
      padding-left: 0 !important;
      div {
        flex: 1;
      }
      .thinkingtext {
        color: #3b3636;
      }
    }
  }
}
.process {
  margin-top: 20px;
  padding: 0px 5px 5px 0px;
        .left-tool{
        float: left;
        .left-processbgitem{
          cursor: pointer;
          display: flex;
          align-items: center;
          background: #FFF8F3;
          font-size: 14px;
          padding: 6px 20px;
          color: #FF7957;
          .buttonText{
            font-size: 14px;
            margin-left: 4px;
          }
          float: left;
          border-left: 1px solid FFCDC0;
        }
      }
  .processbgitem {
        background: #FFF8F3;
        border-radius: 4px;
        float: right;
        cursor: pointer;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 14px;
        color: #FF7957;
        letter-spacing: 0;
        text-align: center;
        padding: 6px 20px;
    .turn {
      transform-origin: center;
      animation: spin 1s linear infinite;
    }
  }
}
.processbg {
  background-color: #fff;
  border-radius: 4px;
  font-size: 16px;
  border: 1px solid #9a8c8c26;
  margin-top: 3px;
  .thinkingtext {
    padding: 10px 7px;
      word-wrap: break-word;
  white-space: pre-line; /* 保留空白符并且换行 */
  max-width: 100%; /* 确保最大宽度不超过父元素 */
  }
  .retract-img {
    float: right;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes textrun {
  0% {
    transform: translateY(0px);
  }
  20% {
    transform: translateY(-2px);
  }
  40%,
  100% {
    transform: translateY(0px);
  }
}
</style>
