<template>
  <div ref="PchartContainer" class="pie-chart-container"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  props: {
    PiechartData: {
      type: Object,
      required: true,
    },
    pieChartDownload: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.initChart();
  },
  watch: {
    PiechartData(newdata) {
      this.updateChart(newdata);
    },
    pieChartDownload(newVal) {
      console.log(newVal)
      if (newVal) {
        this.downloadChart();
      }
    }
  },
  methods: {
    initChart() {
      this.PchartInstance = echarts.init(this.$refs.PchartContainer);
      this.updateChart(this.PiechartData);
    },
    updateChart(data) {
      console.log(data);
      const processedData = this.processData(data.columns, data.datas);
      const options = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          // orient:'vertical',
          left: "0px",
          type: "scroll",
          formatter: (name) => {
            if (!name) return "";
            return this.LineFeedLabel(name, 6);
          },
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 14,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: processedData.map((item) => ({
              value: item.value,
              name: item.name,
            })),
          },
        ],
      };

      this.PchartInstance.setOption(options);
    },
    processData(columns, data) {
      console.log(columns, data);
      const code = columns
        .filter((column) => column.type === "dimension")
        .map((column) => column.code);
      const measures = columns
        .filter((column) => column.type === "measure")
        .map((column) => column.code);
      const pieData = measures.flatMap((measure) => {
        const groupedData = data.reduce((acc, item) => {
          const key = item[code[0]]; // 获取当前购买方作为分组键
          if (!acc[key]) acc[key] = { name: key, values: {} };
          acc[key].values[measure] = item[measure];
          return acc;
        }, {});

        return Object.values(groupedData).map((group) => ({
          name: group.name,
          value: measures.reduce((total, measure) => {
            const value = group.values[measure];
            return total + (isNaN(value) ? 0 : value);
          }, 0),
        }));
      });
      return pieData;
    },
    LineFeedLabel(data, length) {
      //data 要处理的字符串
      //length 每行显示长度
      let word = "";
      let num = Math.ceil(data.length / length); // 向上取整数
      // 一行展示length个
      if (num > 1) {
        for (let i = 1; i <= num; i++) {
          word += data.substr((i - 1) * length, length);
          if (i < num) {
            word += "\n";
          }
        }
      } else {
        word += data.substr(0, length);
      }
      return word;
    },
    downloadChart() {
      const imgBase64 = this.PchartInstance.getDataURL({
        type: 'png',
        pixelRatio: 2,
        backgroundColor: '#fff'
      });
      const link = document.createElement('a');
      link.href = imgBase64;
      link.download = 'chart.png';
      link.click();
    }
  },
};
</script>

<style scoped>
.pie-chart-container {
  height: 400px;
}
</style>
