<template>
  <div class="layout-wrap">
    <div class="layout-header">
      <div class="left">
        <img class="logo" src="~@/assets/img/layout/logo.png" alt="" />
        <!-- <img src="~@/assets/img/layout/web-name.png" alt="" /> -->
        <div class="project-title">数据大模型机器人</div>
        <h1></h1>
      </div>
      <div class="right">
        <el-popover
          placement="bottom"
          v-model:visible="avterInfoPull"
          popper-class="theme"
        >
          <template #reference>
            <div class="user-info text-hover">
              <svg-icon class="avter" name="avter" size="24"></svg-icon>
              <p class="name">上官飞飞</p>
              <svg-icon
                v-if="avterInfoPull"
                class="pull"
                name="avter-up"
                size="16"
              ></svg-icon>
              <svg-icon
                v-else
                class="pull"
                name="avter-down"
                size="16"
              ></svg-icon>
            </div>
          </template>
          <div class="popper-list">
            <div class="popper-item">
              <div class="ellipsis">切换账号</div>
            </div>
          </div>
        </el-popover>
      </div>
    </div>
    <div class="layout-body">
      <div class="nav">
        <div class="menu-list">
          <div
            class="menu-item"
            v-for="item in navData"
            :key="item.name"
            :class="item.route === route.name ? 'act' : ''"
            @click="gotoView(item.route)"
          >
            <el-popover
              v-if="item.children"
              placement="right"
              popper-class="theme"
            >
              <template #reference>
                <div>
                  <svg-icon class="img" :name="item.icon" size="20"></svg-icon>
                  <div class="name">{{ item.name }}</div>
                </div>
              </template>
              <div v-if="['智能', '应用'].includes(item.name)">
                <el-tabs v-model="horTabName[item.name]" class="hor-tabs">
                  <el-tab-pane
                    v-for="(horItem, index) in item.children"
                    :key="horItem.name"
                    :label="horItem.name"
                    :name="horItem.name"
                  >
                    <template #label>
                      <div class="custom-tabs-label">
                        <svg-icon
                          class="img"
                          :name="horItem.icon"
                          size="20"
                        ></svg-icon>
                        <div>{{ horItem.name }}</div>
                      </div>
                    </template>
                    <el-menu :default-openeds="['0']" class="popper-list">
                      <template v-for="(subItem, index) in horItem.children">
                        <el-menu-item
                          :index="'' + index"
                          v-if="!subItem.children"
                          :key="subItem.name"
                          class="popper-item"
                          :class="subItem.route === route.name ? 'active' : ''"
                          @click="gotoView(subItem.route)"
                        >
                          <template #title>
                            <div class="ellipsis">{{ subItem.name }}</div>
                          </template>
                        </el-menu-item>
                        <el-sub-menu
                          v-else
                          :index="'' + index"
                          :key="subItem.name"
                        >
                          <template #title>
                            <div class="ellipsis">{{ subItem.name }}</div>
                          </template>
                          <el-menu-item
                            v-for="(subSubItem, i) in subItem.children"
                            :index="'' + index + i"
                            :key="subSubItem.name"
                            :class="
                              subSubItem.route === route.name ? 'active' : ''
                            "
                            @click="gotoView(subSubItem.route)"
                          >
                            <template #title>
                              <div class="ellipsis">{{ subSubItem.name }}</div>
                            </template>
                          </el-menu-item>
                        </el-sub-menu>
                      </template>
                    </el-menu>
                  </el-tab-pane>
                </el-tabs>
              </div>
              <el-menu v-else :default-openeds="['0']" class="popper-list">
                <template v-for="(subItem, index) in item.children">
                  <el-menu-item
                    :index="'' + index"
                    v-if="!subItem.children"
                    :key="subItem.name"
                    class="popper-item"
                    :class="subItem.route === route.name ? 'active' : ''"
                    @click="gotoView(subItem.route)"
                  >
                    <template #title>
                      <div class="ellipsis">{{ subItem.name }}</div>
                    </template>
                  </el-menu-item>
                  <el-sub-menu v-else :index="'' + index" :key="subItem.name">
                    <template #title>
                      <div class="ellipsis">{{ subItem.name }}</div>
                    </template>
                    <el-menu-item
                      v-for="(subSubItem, i) in subItem.children"
                      :index="'' + index + i"
                      :key="subSubItem.name"
                      :class="subSubItem.route === route.name ? 'active' : ''"
                      @click="gotoView(subSubItem.route)"
                    >
                      <template #title>
                        <div class="ellipsis">{{ subSubItem.name }}</div>
                      </template>
                    </el-menu-item>
                  </el-sub-menu>
                </template>
              </el-menu>
              <!-- <div class="popper-list" v-else>
                <div v-for="subItem in item.children" :key="subItem.name" class="popper-item" :class="subItem.active ? 'active' : ''">
                  <div class="ellipsis">{{ subItem.name }}</div>
                </div>
              </div> -->
            </el-popover>
            <div v-else>
              <svg-icon class="img" :name="item.icon" size="20"></svg-icon>
              <div class="name">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <div class="bottom-list">
          <div class="bottom-item">
            <el-popover placement="right" popper-class="theme">
              <template #reference>
                <div>
                  <svg-icon class="img" name="outer-link" size="24"></svg-icon>
                </div>
              </template>
              <div class="popper-list">
                <div
                  v-for="item in systemList"
                  :key="item.name"
                  class="popper-item"
                  :class="item.active ? 'active' : ''"
                  @click="gotoView(item.route)"
                >
                  <div class="ellipsis">{{ item.name }}</div>
                </div>
              </div>
            </el-popover>
          </div>
        </div>
      </div>
      <div class="layout-main">
        <TagsView v-if="!(route.meta && route.meta.noTagsView)" />
        <router-view class="layout-content"></router-view>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useRouterHook } from "@/hooks/useRouter"
import { useRoute, useRouter } from "vue-router";
import { TagsView } from "./components/index";
import { reactive, ref } from "vue";
import img from "@/assets/img/layout/logo.png";
const avterInfoPull = ref(false);
const route = useRoute();
const router = useRouter();
import { useTitle } from "@/hooks/useTitle"
const { setProjectTitle } = useTitle()
setProjectTitle('数据大模型机器人')
const horTabName = ref({
  智能: "智能感知",
  应用: "智能巡检",
});
const navData = reactive([
  {
    route: "dashboard",
    icon: "talk",
    name: "对话",
  },
  {
    icon: "mirror",
    name: "数据产品",
    children: [
      {
        name: "数据产品集市",
      },
      {
        name: "数据产品定制",
      },
    ],
  },
    {
    icon: "data",
    name: "数据指标",
    children: [
      {
        name: "数据指标体系",
        children: [
          { name: "指标数据元素" },
          { name: "维度数据元素" },
          { name: "指标主题管理" },
          { name: "指标口径管理" },
          ]
      },
      {
        name: "数据指标探索",
        children: [
          { name: "指标数据统计" },
          ]
      },
    ],
  },
  {
    icon: "mirror",
    name: "数据管理",
    children: [
    {name: '数据检索',route:'recordRetrieval'},
    {
    name: '数据模型',
      children: [
      {name: '数据对象',route:'recordTarget'},    
      {name: '对象标签',route:'recorLabels'},
      {name: '关系模型',},]
    },

    {
    name: '数据管理',
      children: [{
        name: '数据维护',
        route:'recordMaintenance'
      },{name: '关系维护',route:'recorRelationship'},]
    },
    {
      name: '数据权限',
        children: [{
          name: '对象权限',route:"recorCharacter",
        },
        {name: '字段权限',route:'recorField'},]
    },
  ]
  },
  {
    icon: "data",
    name: "智能中台",
    route: "http://192.168.100.251/resource/databus/adp_ai.html"
  },
    {
    icon: "smart",
    name: "数据中台",
    route: "http://192.168.100.191:11008/resource/databus/index.html"
  },
  {
    icon: "money",
    name: "知识管理",
    children: [{
    name: '知识全文检索',
    route: 'retrieval',
    },
    {
    name: '知识维护管理',
    route: 'classification',
    },{
    name: '知识分类目录',
    route: 'classificationEdit',
    },
    {
    name: '知识分类标签',
    route: 'label',
    },
    ]
  },
]);
const urlList = reactive([]);
const systemList = reactive([{
  name: '产品介绍'
}, {
  name: '使用指南'
}, {
  name: '运行监控'
}, {
  name: '系统设置'
}, {
  name: '工具拓展'
}, {
  name: '数据字典',
  route: 'dictionary',
}])

const routerHook = useRouterHook()
routerHook.switchIndexType('data_ops')
function gotoView(route) {
  if (!route) return;
  if (route.startsWith("https://") || route.startsWith("http://")) {
    return window.open(route);
  }
  routerHook.push({ name: route })
}
</script>
<style lang="scss" scoped>
.layout-wrap {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: url(@/assets/img/layout/bg.png) center center no-repeat;
  background-size: cover;
}
.layout-header {
  height: 56px;
  flex-shrink: 0;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 20px 0 #acb2d233;
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  .left,
  .right {
    display: flex;
    align-items: center;
  }
  .user-info {
    display: flex;
    align-items: center;
  }
  .logo {
    margin-right: 10px;
  }
  .avter {
    border-radius: 100%;
    margin-right: 10px;
  }
  .name {
    font-size: 14px;
  }
  .pull {
    margin-left: 10px;
  }
}
.layout-body {
  flex: 1;
  height: 0;
  display: flex;
  padding: 20px;
}
.layout-main {
  flex: 1;
  width: 0;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.layout-content {
  flex: 1;
  height: 0;
  width: 100%;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 0 20px 0 #acb2d24d;
  border-radius: 0 4px 4px 4px;
}
.nav {
  padding: 7px;
  margin-right: 20px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  .menu-list {
    flex: 1;
    height: 0;
    overflow-y: auto;
    margin-bottom: 10px;
  }
  .menu-item {
    text-align: center;
    padding: 5px 9px;
    cursor: pointer;
    border-radius: 4px;
    margin-bottom: 5px;
    color: #484f6d;
    .img {
      display: inline-block;
      margin-bottom: 2px;
    }
    &.act,
    &:hover {
      color: $primary-color;
      background: #fff4f4;
    }
  }
  .bottom-list {
    flex-shrink: 0;
  }
  .bottom-item {
    margin-top: 20px;
    text-align: center;
    .img {
      width: 24px;
      height: 24px;
    }
  }
}
.popper-list {
  max-width: auto;
  padding: 12px 0 2px;
  :deep(.el-sub-menu__title) {
    height: 30px;
    line-height: 30px;
    margin-bottom: 10px;
    &:hover {
      background: #fff0f0;
      color: $primary-color;
    }
  }
  &.el-menu--vertical {
    :deep(
        :not(.el-menu--collapse):not(.el-menu--popup-container) .el-menu-item
      ) {
      padding-left: calc(
        var(--el-menu-base-level-padding) + var(--el-menu-level) * 10px
      );
    }
  }
  :deep(.el-menu-item) {
    height: 30px;
    line-height: 30px;
    margin-bottom: 10px;
    &:hover {
      background: #fff0f0;
      color: $primary-color;
    }
  }
  .popper-item {
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
    height: 30px;
    line-height: 30px;
    padding: 0 15px;
    &.active {
      color: $primary-color;
      background: #fff0f0;
      // &::before{
      //   content: '.';
      //   font-size: 0;
      //   width: 1px;
      //   height: 10px;
      //   position: absolute;
      //   left: 10px;
      //   top: 50%;
      //   margin-top: -5px;
      //   background: $primary-color;
      // }
    }
    &:hover {
      background: #fff0f0;
      color: $primary-color;
    }
  }
}
.hor-tabs {
  :deep(.el-tabs__nav-wrap::after) {
    display: none;
  }
  :deep(.el-tabs__header) {
    margin-bottom: 10px;
    background-image: linear-gradient(to bottom, #fff7f1, #fff);
    .el-tabs__item {
      padding: 10px 0 0;
      margin: 0 12px;
      height: 70px;
    }
  }
  .custom-tabs-label {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div {
      margin-top: 4px;
    }
  }
}
.UpDownspacing {
  margin-bottom: -10px;
  margin-top: -10px;
}
.spacing {
  text-indent: 35px;
}
</style>
