<script setup lang="ts">
import researchChatbox from "@/components/researchChatbox.vue";

function getQueryString(paramName) {
    var querystring = window.location.search;
    var params = new URLSearchParams(querystring);
    return params.get(paramName);
  }
  function clearToken() {
    window.localStorage.removeItem("userid")
    window.localStorage.removeItem("username")
    window.location.href = "https://itim.cclzj.cnooc:444";
  }

  const token = getQueryString('t');
  if (token) {
    var auth = atob(token)
    var datas = auth.split('_')
    if (datas.length<2) {
      clearToken()
    }
    else {
      var userid = auth.split('_')[0]
      var username = decodeURIComponent(auth.split('_')[1])
      var isadmin = decodeURIComponent(auth.split('_')[2])

      if (username===undefined ||userid===undefined){
        clearToken()
      } 
      else{
        window.localStorage["userid"] = userid;
        window.localStorage["username"] = username;
        window.localStorage["isadmin"] = isadmin;
      }
    }
  } else {
    clearToken()
  }
</script>
<template>
  <div class="large-model">
    <researchChatbox></researchChatbox>
  </div>
</template>
<style lang='less' scoped>
.large-model {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
</style>

