<template>
  <div>
    <div
      ref="dynamicContent"
      v-html="htmlWithoutScripts"
      class="ans"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    html: {
      type: String,
      required: true,
    },
    inlineScripts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      htmlWithoutScripts: "",
    };
  },
  watch: {
    html: {
      handler(newInlineScripts) {
        // console.log(newInlineScripts);
        if (newInlineScripts !== "") {
          this.htmlWithoutScripts = this.replaceHtml(newInlineScripts);
          this.replaceSc(newInlineScripts);
        } else {
          this.htmlWithoutScripts = "";
        }
      },
    },
    inlineScripts: {
      handler(newInlineScripts) {
        // console.log(newInlineScripts);
      },
      immediate: true,
    },
  },
  mounted() {
    this.$refs.dynamicContent.addEventListener("click", this.handleCustomClick);
  },
  beforeDestroy() {
    this.$refs.dynamicContent.removeEventListener(
      "click",
      this.handleCustomClick
    );
  },
  methods: {
    handleCustomClick(event) {
      const target = event.target;
      if (target.id === "submitButton") {
        this.$emit("submit", true);
      }
    },
    replaceHtml(htmlString) {
      const scriptPattern =
        /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/i;
      const scriptMatch = htmlString.match(scriptPattern);

      if (!scriptMatch) {
        throw new Error("、、.");
      }

      const scriptStartIndex = htmlString.indexOf(scriptMatch[0]);
      const precedingHTML = htmlString.slice(0, scriptStartIndex);
      return precedingHTML;
    },
    replaceSc(data) {
      const scriptPattern =
        /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/g;
      const scriptMatches = data.matchAll(scriptPattern);

      const extractedScripts = [];
      for (const match of scriptMatches) {
        const script = match[0].replace(/<\/?script[^>]*>/g, "");
        extractedScripts.push(script);
      }
      const checkAndExecuteScripts = () => {
        const userInput = document.getElementById("userInput");
        const submitButton = document.getElementById("submitButton");

        if (userInput && submitButton) {
          extractedScripts.forEach((scriptString) => {
            const scriptElement = document.createElement("script");
            scriptElement.text = scriptString.replace(/<\/?script[^>]*>/g, "");
            document.head.appendChild(scriptElement);
          });
        } else {
          setTimeout(checkAndExecuteScripts, 500);
        }
      };

      checkAndExecuteScripts();
    },
  },
};
</script>
<style scoped>
.ans {
  /* width: 100%;
    height: 20%;
    z-index: 999;
    bottom: 13%;
    position: absolute;
    border-radius: 5px;
    overflow: auto;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1); */
}
</style>
