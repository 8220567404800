import axios from 'axios';
const baseURL = import.meta.env.VITE_BASE_URL
// const baseURL ="https://platform.uat.anyonedev.com"
const request = axios.create({
  baseURL: baseURL, 
  timeout: 0, 
  withCredentials: false,
});
request.interceptors.response.use((config) => {
  return config
}, (error) => {
  // 如果请求出错了，还没有发送出去，就会到这里
  return Promise.reject(error)
});
// 拦截响应，如果请求是404怎么处理 成功怎么返回
request.interceptors.response.use((response) => {
  if (response.status == 200) {
    return response.data
  } else {
    return response
  };
}, (error) => {
  // 如果请求出错了，还没有发送出去，就会到这里
  return Promise.reject(error)
})
export default request 