<template>
  <div class="layout-wrap">
    <div class="layout-header">
      <div class="left">
        <img class="logo" src="~@/assets/img/layout/logo.png" alt="" />
        <div class="project-title">IT大模型机器人</div>
        <h1></h1>
      </div>
      <div class="right">
        <el-popover
          placement="bottom"
          v-model:visible="avterInfoPull"
          popper-class="theme"
        >
          <template #reference>
            <div class="user-info text-hover">
              <svg-icon class="avter" name="avter" size="24"></svg-icon>
              <p class="name">上官飞飞</p>
              <svg-icon
                v-if="avterInfoPull"
                class="pull"
                name="avter-up"
                size="16"
              ></svg-icon>
              <svg-icon
                v-else
                class="pull"
                name="avter-down"
                size="16"
              ></svg-icon>
            </div>
          </template>
          <div class="popper-list">
            <div class="popper-item">
              <div class="ellipsis">切换账号</div>
            </div>
          </div>
        </el-popover>
      </div>
    </div>
    <div class="layout-body">
      <div class="nav">
        <div class="menu-list">
          <div
            class="menu-item"
            v-for="item in navData"
            :key="item.name"
            :class="item.route === route.name ? 'act' : ''"
            @click="gotoView(item.route)"
          >
            <el-popover
              v-if="item.children"
              placement="right"
              popper-class="theme"
            >
              <template #reference>
                <div>
                  <svg-icon class="img" :name="item.icon" size="20"></svg-icon>
                  <div class="name">{{ item.name }}</div>
                </div>
              </template>
              <div v-if="['智能中台','智能运维','数据资产'].includes(item.name)">
                <el-tabs v-model="horTabName[item.name]" class="hor-tabs">
                  <el-tab-pane
                    v-for="(horItem, index) in item.children"
                    :key="horItem.name"
                    :label="horItem.name"
                    :name="horItem.name"
                  >
                    <template #label>
                      <div class="custom-tabs-label">
                        <svg-icon
                          class="img"
                          :name="horItem.icon"
                          size="20"
                        ></svg-icon>
                        <div>{{ horItem.name }}</div>
                      </div>
                    </template>
                    <el-menu :default-openeds="['0']" class="popper-list">
                      <template v-for="(subItem, index) in horItem.children">
                        <el-menu-item
                          :index="'' + index"
                          v-if="!subItem.children"
                          :key="subItem.name"
                          class="popper-item"
                          :class="subItem.route === route.name ? 'active' : ''"
                          @click="gotoView(subItem.route)"
                        >
                          <template #title>
                            <div class="ellipsis">{{ subItem.name }}</div>
                          </template>
                        </el-menu-item>
                        <el-sub-menu
                          v-else
                          :index="'' + index"
                          :key="subItem.name"
                        >
                          <template #title>
                            <div class="ellipsis">{{ subItem.name }}</div>
                          </template>
                          <el-menu-item
                            v-for="(subSubItem, i) in subItem.children"
                            :index="'' + index + i"
                            :key="subSubItem.name"
                            :class="
                              subSubItem.route === route.name ? 'active' : ''
                            "
                            @click="gotoView(subSubItem.route)"
                          >
                            <template #title>
                              <div class="ellipsis">{{ subSubItem.name }}</div>
                            </template>
                          </el-menu-item>
                        </el-sub-menu>
                      </template>
                    </el-menu>
                  </el-tab-pane>
                </el-tabs>
              </div>
              <el-menu v-else :default-openeds="['0']" class="popper-list">
                <template v-for="(subItem, index) in item.children">
                  <el-menu-item
                    :index="'' + index"
                    v-if="!subItem.children"
                    :key="subItem.name"
                    class="popper-item"
                    :class="subItem.route === route.name ? 'active' : ''"
                    @click="gotoView(subItem.route)"
                  >
                    <template #title>
                      <div class="ellipsis">{{ subItem.name }}</div>
                    </template>
                  </el-menu-item>
                  <el-sub-menu v-else :index="'' + index" :key="subItem.name">
                    <template #title>
                      <div class="ellipsis">{{ subItem.name }}</div>
                    </template>
                    <el-menu-item
                      v-for="(subSubItem, i) in subItem.children"
                      :index="'' + index + i"
                      :key="subSubItem.name"
                      :class="subSubItem.route === route.name ? 'active' : ''"
                      @click="gotoView(subSubItem.route)"
                    >
                      <template #title>
                        <div class="ellipsis">{{ subSubItem.name }}</div>
                      </template>
                    </el-menu-item>
                  </el-sub-menu>
                </template>
              </el-menu>
              <!-- <div class="popper-list" v-else>
                <div v-for="subItem in item.children" :key="subItem.name" class="popper-item" :class="subItem.active ? 'active' : ''">
                  <div class="ellipsis">{{ subItem.name }}</div>
                </div>
              </div> -->
            </el-popover>
            <div v-else>
              <svg-icon class="img" :name="item.icon" size="20"></svg-icon>
              <div class="name">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <div class="bottom-list">
          <div class="bottom-item">
            <el-popover placement="right" popper-class="theme">
              <template #reference>
                <div>
                  <svg-icon
                    class="img"
                    name="other-platform"
                    size="24"
                  ></svg-icon>
                </div>
              </template>
              <div class="popper-list">
                <div
                  v-for="item in urlList"
                  :key="item.name"
                  class="popper-item"
                  :class="item.active ? 'active' : ''"
                >
                  <div class="ellipsis">{{ item.name }}</div>
                </div>
              </div>
            </el-popover>
          </div>
          <div class="bottom-item">
            <el-popover placement="right" popper-class="theme">
              <template #reference>
                <div>
                  <svg-icon class="img" name="outer-link" size="24"></svg-icon>
                </div>
              </template>
              <div class="popper-list">
                <div
                  v-for="item in systemList"
                  :key="item.name"
                  class="popper-item"
                  :class="item.active ? 'active' : ''"
                  @click="gotoView(item.route)"
                >
                  <div class="ellipsis">{{ item.name }}</div>
                </div>
              </div>
            </el-popover>
          </div>
        </div>
      </div>
      <div class="layout-main">
        <TagsView v-if="!(route.meta && route.meta.noTagsView)" />
        <router-view class="layout-content"></router-view>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useRouterHook } from "@/hooks/useRouter"
import { useRoute, useRouter } from "vue-router";
import { TagsView } from "./components/index";
import { reactive, ref } from "vue";
import img from "@/assets/img/layout/logo.png";
const avterInfoPull = ref(false);
const route = useRoute();
const router = useRouter();
import { useTitle } from "@/hooks/useTitle";
const { setProjectTitle } = useTitle();
setProjectTitle("IT大模型机器人");
// const horTabName = ref({
//   智能中台: "智能感知",
//   // 智能运维: "智能巡检",
//   数据资产: "数据资源",
// });
// const navData = reactive([
//   {
//     route: "dashboard",
//     icon: "talk",
//     name: "对话",
//   },
//   {
//     icon: "mirror",
//     name: "数据产品",
//     children: [
//       {
//         name: "资产全景视图",
//       },
//       {
//         name: "数据产品集市",
//       },
//     ],
//   },
//   {
//     icon: "data",
//     name: "数据资产",
//     children: [
//       {
//         name: "数据资源",
//         icon: "perception",
//         children: [
//           { name: "数据检索", route: "recordRetrieval" },
//           {
//             name: "数据模型",
//             children: [
//               { name: "数据对象", route: "recordTarget" },
//               { name: "对象标签", route: "recorLabels" },
//               { name: "关系模型" },
//             ],
//           },

//           {
//             name: "数据管理",
//             children: [
//               {
//                 name: "数据维护",
//                 route: "recordMaintenance",
//               },
//               { name: "关系维护", route: "recorRelationship" },
//             ],
//           },
//           {
//             name: "数据权限",
//             children: [
//               {
//                 name: "对象权限",
//                 route: "recorCharacter",
//               },
//               { name: "字段权限", route: "recorField" },
//             ],
//           },
//         ],
//       },
//       {
//         name: "数据指标",
//         icon: "cognition",
//         children: [
//           {
//             name: "数据指标体系",
//             icon: "",
//             children: [
//               { name: "指标数据元素", icon: "" },
//               { name: "维度数据元素", icon: "" },
//               { name: "指标主题管理", icon: "" },
//               { name: "指标口径管理", icon: "" },
//             ],
//           },
//           {
//             name: "数据指标探索",
//             icon: "",
//             children: [{ name: "指标数据统计", icon: "" }],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     icon: "money",
//     name: "资产管理",
//     children: [
//       {
//         name: "资产管理",
//         children: [
//           {
//             route: "richManageMaintenanceList",
//             name: "资产维护",
//           },
//           {
//             route: "richManageClassList",
//             name: "分类目录",
//           },
//           {
//             route: "richManageTag",
//             name: "资产标签",
//           },
//         ],
//       },
//       {
//         name: "资产模型",
//         children: [
//           {
//             route: "richModemanage",
//             name: "模型管理",
//           },
//         ],
//       },
//       {
//         name: "资产发现",
//         children: [
//           {
//             route: "richFindRule",
//             name: "发现规则",
//           },
//           {
//             route: "richFindComfirm",
//             name: "资产确认",
//           },
//         ],
//       },
//     ],
//   },
//   {
//     icon: "smart",
//     name: "智能中台",
//     children: [
//       {
//         icon: "perception",
//         name: "智能感知",
//         children: [
//           {
//             name: "采集策略",
//             children: [
//               {
//                 name: "资产测点管理",
//                 route: "asset",
//               },
//               {
//                 name: "采集策略管理",
//                 route: "collectionStrategy",
//               },
//             ],
//           },
//           {
//             name: "采集监控",
//             children: [
//               {
//                 name: "网关实例监控",
//               },
//               {
//                 name: "数据采集统计",
//               },
//             ],
//           },
//           {
//             name: "采集网关",
//             children: [
//               {
//                 name: "采集网关实例",
//               },
//               {
//                 name: "采集网关协议",
//               },
//             ],
//           },
//         ],
//       },
//       {
//         icon: "cognition",
//         name: "智能认知",
//         children: [
//           {
//             name: "认知策略",
//             children: [
//               {
//                 name: "实时策略",
//               },
//               {
//                 name: "智能策略",
//               },
//             ],
//           },
//           {
//             name: "认知事件",
//             children: [
//               {
//                 name: "事件记录",
//               },
//               {
//                 name: "事件统计",
//               },
//             ],
//           },
//           {
//             name: "事件通知",
//             children: [
//               {
//                 name: "我的消息",
//               },
//               {
//                 name: "历史消息",
//               },
//               {
//                 name: "用户配置",
//               },
//             ],
//           },
//         ],
//       },
//       {
//         icon: "decision",
//         name: "智能决策",
//         children: [
//           {
//             name: "决策逻辑",
//             children: [
//               {
//                 name: "决策策略管理",
//               },
//               {
//                 name: "策略执行统计",
//               },
//             ],
//           },
//           {
//             name: "人工介入",
//             children: [
//               {
//                 name: "人工介入记录",
//               },
//               {
//                 name: "人工介入统计",
//               },
//             ],
//           },
//           {
//             name: "异常管理",
//             children: [
//               {
//                 name: "异常回答管理",
//               },
//               {
//                 name: "异常回答统计",
//               },
//             ],
//           },
//         ],
//       },
//       {
//         icon: "control",
//         name: "智能控制",
//         children: [
//           {
//             name: "控制动作",
//             children: [
//               {
//                 name: "资产动作",
//               },
//               {
//                 name: "动作标签",
//               },
//               {
//                 name: "执行记录",
//               },
//             ],
//           },
//           {
//             name: "控制场景",
//             children: [
//               {
//                 name: "场景管理",
//               },
//               {
//                 name: "执行记录",
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     icon: "knowledge",
//     name: "知识管理",
//     children: [
//       {
//         name: "知识全文检索",
//         route: "retrieval",
//       },
//       {
//         name: "知识维护管理",
//         route: "classification",
//       },
//       {
//         name: "知识分类目录",
//         route: "classificationEdit",
//       },
//       {
//         name: "知识分类标签",
//         route: "label",
//       },
//     ],
//   },
// ]);
const horTabName = ref({
   '智能中台': "智能感知",
  '智能运维': "智能巡检",
  '数据资产': "数据资源",
});
const navData = reactive([
  {
    route: "index",
    icon: "talk",
    name: "对话",
  },
  {
    icon: "mirror",
    name: "数据产品",
    children: [
      {
        name: "资产全景视图",
      },
      {
        name: "数据产品集市",
      },
    ],
  },
  {
    icon: "mirror",
    name: "智能运维",
    children: [
      {
        icon: "perception",
        name: "智能巡检",
        children: [
          {
            name: "巡检报告",
            children: [
              {
                name: "每次巡检报告",
              },
              {
                name: "巡检异常记录",
              },
            ],
          },
          { name: "巡检方案" },
          { name: "报告模版" },
        ],
      },
      {
        icon: "cognition",
        name: "故障定位",
        children: [{ name: "故障根因分析" }, { name: "故障告警管理" }],
      },
      {
        icon: "decision",
        name: "运维管理",
        children: [
          {
            name: "事件闭环",
            children: [
              {
                name: "事件工单发起",
              },
              {
                name: "我的事件工单",
              },
              {
                name: "事件工单管理",
              },
            ],
          },
          {
            name: "变更管理",
            children: [
              {
                name: "变更日历",
              },
              {
                name: "变更计划",
              },
              {
                name: "变更结果",
              },
            ],
          },
          { name: "值班管理" },
          { name: "发布管理" },
        ],
      },
      {
        icon: "control",
        name: "安全治理",
      },
    ],
  },
  {
    icon: "data",
    name: "数据资产",
    children: [
      {
        name: "数据资源",
        icon: "perception",
        children: [
          { name: "数据检索", route: "recordRetrieval" },
          {
            name: "数据模型",
            icon: "cognition",
            children: [
              { name: "数据对象", route: "recordTarget" },
              { name: "对象标签", route: "recorLabels" },
              { name: "关系模型" },
            ],
          },
          {
            name: "数据管理",
            icon: "decision",
            children: [
              {
                name: "数据维护",
                route: "recordMaintenance",
              },
              { name: "关系维护", route: "recorRelationship" },
            ],
          },
          {
            name: "数据权限",
            icon: "control",
            children: [
              {
                name: "对象权限",
                route: "recorCharacter",
              },
              { name: "字段权限", route: "recorField" },
            ],
          },
        ],
      },
      {
        name: "数据指标",
        icon: "cognition",
        children: [
          {
            name: "数据指标体系",
            icon: "",
            children: [{ name: "指标数据元素", icon: "" },
            { name: "维度数据元素", icon: "" },
            { name: "指标主题管理", icon: "" },
            { name: "指标口径管理", icon: "" },],
 
          },
                    {
            name: "数据指标探索",
            icon: "",
            children: [{ name: "指标数据统计", icon: "" },],
 
          },
        ],
      },
    ],
  },
  {
    icon: "money",
    name: "资产管理",
    children: [
      {
        name: "资产管理",
        children: [
          {
            route: "richManageMaintenanceList",
            name: "资产维护",
          },
          {
            route: "richManageClassList",
            name: "分类目录",
          },
          {
            route: "richManageTag",
            name: "资产标签",
          },
        ],
      },
      {
        name: "资产模型",
        children: [
          {
            route: "richModemanage",
            name: "模型管理",
          },
        ],
      },
      {
        name: "资产发现",
        children: [
          {
            route: "richFindRule",
            name: "发现规则",
          },
          {
            route: "richFindComfirm",
            name: "资产确认",
          },
        ],
      },
    ],
  },
  {
    icon: "smart",
    name: "智能中台",
    children: [
      {
        icon: "perception",
        name: "智能感知",
        children: [
          {
            name: "采集策略",
            children: [
              {
                name: "资产测点管理",
                route: "asset",
              },
              {
                name: "采集策略管理",
                route: "collectionStrategy",
              },
            ],
          },
          {
            name: "采集监控",
            children: [
              {
                name: "网关实例监控",
              },
              {
                name: "数据采集统计",
              },
            ],
          },
          {
            name: "采集网关",
            children: [
              {
                name: "采集网关实例",
              },
              {
                name: "采集网关协议",
              },
            ],
          },
        ],
      },
      {
        icon: "cognition",
        name: "智能认知",
        children: [
          {
            name: "认知策略",
            children: [
              {
                name: "实时策略",
              },
              {
                name: "智能策略",
              },
            ],
          },
          {
            name: "认知事件",
            children: [
              {
                name: "事件记录",
              },
              {
                name: "事件统计",
              },
            ],
          },
          {
            name: "事件通知",
            children: [
              {
                name: "我的消息",
              },
              {
                name: "历史消息",
              },
              {
                name: "用户配置",
              },
            ],
          },
        ],
      },
      {
        icon: "decision",
        name: "智能决策",
        children: [
          {
            name: "决策逻辑",
            children: [
              {
                name: "决策策略管理",
              },
              {
                name: "策略执行统计",
              },
            ],
          },
          {
            name: "人工介入",
            children: [
              {
                name: "人工介入记录",
              },
              {
                name: "人工介入统计",
              },
            ],
          },
          {
            name: "异常管理",
            children: [
              {
                name: "异常回答管理",
              },
              {
                name: "异常回答统计",
              },
            ],
          },
        ],
      },
      {
        icon: "control",
        name: "智能控制",
        children: [
          {
            name: "控制动作",
            children: [
              {
                name: "资产动作",
              },
              {
                name: "动作标签",
              },
              {
                name: "执行记录",
              },
            ],
          },
          {
            name: "控制场景",
            children: [
              {
                name: "场景管理",
              },
              {
                name: "执行记录",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    icon: "knowledge",
    name: "知识管理",
    children: [{
    name: '知识全文检索',
    route: 'retrieval',
    },
    {
    name: '知识维护管理',
    route: 'classification',
    },{
    name: '知识分类目录',
    route: 'classificationEdit',
    },
    {
    name: '知识分类标签',
    route: 'label',
    },
    ]
  },
]);
const urlList = reactive([
  {
    name: "数据中台",
  },
  {
    name: "智能平台",
  },
  {
    name: "物联平台",
  },
  {
    name: "平台名平台名平台名平台名",
  },
]);
const systemList = reactive([
  {
    name: "产品介绍",
  },
  {
    name: "使用指南",
  },
  {
    name: "运行监控",
  },
  {
    name: "系统设置",
  },
  {
    name: "工具拓展",
  },
  {
    name: "数据字典",
    route: "dictionary",
  },
]);

const routerHook = useRouterHook()
routerHook.switchIndexType('it_ops')
function gotoView(route) {
  if (!route) return;
  if (route.startsWith("https://") || route.startsWith("http://")) {
    return window.open(route);
  }
  routerHook.push({ name: route });
}
</script>
<style lang="scss" scoped>
.layout-wrap {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: url(@/assets/img/layout/bg.png) center center no-repeat;
  background-size: cover;
}
.layout-header {
  height: 56px;
  flex-shrink: 0;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 20px 0 #acb2d233;
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  .left,
  .right {
    display: flex;
    align-items: center;
  }
  .user-info {
    display: flex;
    align-items: center;
  }
  .logo {
    margin-right: 10px;
  }
  .avter {
    border-radius: 100%;
    margin-right: 10px;
  }
  .name {
    font-size: 14px;
  }
  .pull {
    margin-left: 10px;
  }
}
.layout-body {
  flex: 1;
  height: 0;
  display: flex;
  padding: 20px;
}
.layout-main {
  flex: 1;
  width: 0;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.layout-content {
  flex: 1;
  height: 0;
  width: 100%;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 0 20px 0 #acb2d24d;
  border-radius: 0 4px 4px 4px;
}
.nav {
  padding: 7px;
  margin-right: 20px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  .menu-list {
    flex: 1;
    height: 0;
    overflow-y: auto;
    margin-bottom: 10px;
  }
  .menu-item {
    text-align: center;
    padding: 5px 9px;
    cursor: pointer;
    border-radius: 4px;
    margin-bottom: 5px;
    color: #484f6d;
    .img {
      display: inline-block;
      margin-bottom: 2px;
    }
    &.act,
    &:hover {
      color: $primary-color;
      background: #fff4f4;
    }
  }
  .bottom-list {
    flex-shrink: 0;
  }
  .bottom-item {
    margin-top: 20px;
    text-align: center;
    .img {
      width: 24px;
      height: 24px;
    }
  }
}
.popper-list {
  max-width: auto;
  padding: 12px 0 2px;
  :deep(.el-sub-menu__title) {
    height: 30px;
    line-height: 30px;
    margin-bottom: 10px;
    &:hover {
      background: #fff0f0;
      color: $primary-color;
    }
  }
  &.el-menu--vertical {
    :deep(
        :not(.el-menu--collapse):not(.el-menu--popup-container) .el-menu-item
      ) {
      padding-left: calc(
        var(--el-menu-base-level-padding) + var(--el-menu-level) * 10px
      );
    }
  }
  :deep(.el-menu-item) {
    height: 30px;
    line-height: 30px;
    margin-bottom: 10px;
    &:hover {
      background: #fff0f0;
      color: $primary-color;
    }
  }
  .popper-item {
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
    height: 30px;
    line-height: 30px;
    padding: 0 15px;
    &.active {
      color: $primary-color;
      background: #fff0f0;
      // &::before{
      //   content: '.';
      //   font-size: 0;
      //   width: 1px;
      //   height: 10px;
      //   position: absolute;
      //   left: 10px;
      //   top: 50%;
      //   margin-top: -5px;
      //   background: $primary-color;
      // }
    }
    &:hover {
      background: #fff0f0;
      color: $primary-color;
    }
  }
}
.hor-tabs {
  :deep(.el-tabs__nav-wrap::after) {
    display: none;
  }
  :deep(.el-tabs__header) {
    margin-bottom: 10px;
    background-image: linear-gradient(to bottom, #fff7f1, #fff);
    .el-tabs__item {
      padding: 10px 0 0;
      margin: 0 12px;
      height: 70px;
    }
  }
  .custom-tabs-label {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div {
      margin-top: 4px;
    }
  }
}
.UpDownspacing {
  margin-bottom: -10px;
  margin-top: -10px;
}
.spacing {
  text-indent: 35px;
}
</style>
