<template>
  <div class="category" ref="cateGory"></div>
</template>
<script>
import * as echarts from "echarts";
export default {
  props: {
    cateGoryData: {
      type: Object,
      required: true,
    },
    lineChartDownload: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    cateGoryData(newdata) {
      this.updateChart(newdata);
    },
    lineChartDownload(newVal) {
      if (newVal) {
        this.downloadChart();
      }
    },
  },
  data() {
    return {
      PchartInstance: null,
    };
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      if (!this.PchartInstance) {
        this.PchartInstance = echarts.init(this.$refs.cateGory);
      }
      this.updateChart(this.cateGoryData);
    },
    // initChart() {
    //   this.PchartInstance = echarts.init(this.$refs.cateGory);
    //   this.updateChart(this.cateGoryData);
    // },
    // updateChart(data) {
    //   console.log('Updating chart with data:', data);

    //   if (!this.PchartInstance) {
    //     this.PchartInstance = echarts.init(this.$refs.cateGory);
    //   }
    //   console.log(data.datas)
    //   const processedData = this.processData(data.columns,data.datas);
    //   console.log(processedData)
    //   const options = {
    //     tooltip: { trigger: 'axis' },
    //     xAxis: { type: 'category', data: processedData.xData },
    //     yAxis: { type: 'value' },
    //     series: processedData.ySeries
    //   };
    //   console.log(options)
    //   this.PchartInstance.setOption(options, true); // 使用 setOption 更新图表
    // },
    updateChart(data) {
      console.log(data,111)
      const processedData = this.processData(data.columns, data.datas);
      console.log(processedData)
      const options = {
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: processedData.xData,
        },
        yAxis: {
          type: "value",
        },
        series: processedData.ySeries,
      };

      this.PchartInstance.setOption(options);
    },

    processData(columns, data) {
      var measuresData = {
        xData: [],
        ySeries: [],
      };

      const codes = columns
        .filter((column) => column.type === "dimension")
        .map((column) => column.code);
      const ycodes = columns
        .filter((column) => column.type === "measure")
        .map((column) => column.code);

      // 初始化 ySeries 数组，为每个 ycode 创建对象
      ycodes.forEach((ycode) => {
        measuresData.ySeries.push({
          name: ycode,
          type: "line",
          stack: "total", // 指定所有系列堆叠
          data: [],
        });
      });

      data.forEach((item) => {
        codes.forEach((code) => {
          if (item[code]) {
            measuresData.xData.push(item[code]); // 添加 x 轴数据
            ycodes.forEach((ycode, index) => {
              const value =
                item[ycode] !== undefined
                  ? item[ycode]
                  : Math.floor(Math.random() * 101);
              measuresData.ySeries[index].data.push(value);
            });
          }
        });
      });

      return measuresData;
    },

  downloadChart() {
      const imgBase64 = this.PchartInstance.getDataURL({
        type: 'png',
        pixelRatio: 2,
        backgroundColor: '#fff'
      });
      const link = document.createElement('a');
      link.href = imgBase64;
      link.download = 'chart.png';
      link.click();
    }
  },
};
</script>
<style scoped>
.category {
  height: 400px;
}
</style>
