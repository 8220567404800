<template>
  <table class="table">
    <thead>
      <tr>
        <th v-for="column in tableData.columns" :key="column.code">{{ column.name }}</th>
      </tr>
    </thead> 
    <tbody>
      <tr v-for="item in tableData.datas" :key="item">
        <td v-for="column in tableData.columns" :key="column.code">{{ item[column.code] }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    tableData:{
        type: Object,
        required: true,
    }
  },
watch:{
    tableData(newdata){
    console.log(newdata)
    }
},
}
</script>

<style scoped>
.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 8px;
  border: 1px solid #ccc;
}
</style>
