<template>
  <div ref="BchartContainer" class="chart-container"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  props: {
    BarchartData: {
      type: Object,
      required: true,
    },
    barchartDownload: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    BarchartData(newdata) {
      console.log(newdata)
      this.updateChart(newdata);
    },
    barchartDownload(newVal) {
      if (newVal) {
        this.downloadChart();
      }
    }
  },
  methods: {
    initChart() {
      this.chartInstance = echarts.init(this.$refs.BchartContainer);
      this.updateChart(this.BarchartData);
    },
    updateChart(data) {
      // 清理并重新设置图表配置代码...
      const dimensions = data.columns
        .filter((column) => column.type === "dimension")
        .map((column) => column.code);

      const measures = data.columns
        .filter((column) => column.type === "measure")
        .map((column) => column.code);
        console.log(data.datas)
      const filteredData = data.datas.filter(dataItem =>
        dimensions.every(dimension =>
          dataItem.hasOwnProperty(dimension) &&
          dataItem[dimension] !== undefined &&
          dataItem[dimension] !== null &&
          dataItem[dimension] !== ''
        )
      );
      console.log(filteredData)

      const xAxisDataItem = filteredData.flatMap(dataItem =>
        dimensions.map(dimensionCode => dataItem[dimensionCode])
      );

      const seriesData = measures.map(measure => ({
        name: measure,
        type: "bar",
        stack: "stack",
        data: filteredData.map(dataItem => {
          const value = Number(dataItem[measure]);
          return !isNaN(value) ? value : null;
        }),
        label: {
          show: true,
          position: "inside",
          color: "#fff",
        },
      }));

      const options = {
        legend: {},
        xAxis: {
          type: "category",
          data: xAxisDataItem,
          axisLabel: {
            rotate: 45,
            interval: 0,
            formatter: function (value) {
              if (value == "undefined") return;
              return value.length > 6 ? value.slice(0, 6) + "..." : value;
            },
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params) {
            var values = "";
            if (params[0].axisValueLabel == "undefined") {
              return;
            } else {
              values = params[0].axisValueLabel;
            }
            return values + ": " + params[0].data;
          },
        },
        yAxis: {
          type: "value",
        },
        series: seriesData,
      };

      this.chartInstance.setOption(options);
    },
    downloadChart() {
      const imgBase64 = this.chartInstance.getDataURL({
        type: 'png',
        pixelRatio: 2,
        backgroundColor: '#fff'
      });
      const link = document.createElement('a');
      link.href = imgBase64;
      link.download = 'chart.png';
      link.click();
    }
  },
  mounted() {
    this.initChart();
  }
};
</script>

<style scoped>
.chart-container {
  height: 400px;
}
</style>
