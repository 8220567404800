<template>
  <div ref="BchartContainer" class="chart-container"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  props: {
    BarchartxyData: { 
      type: Object, 
      required: true 
    },
    barchartxyDownload: { 
      type: Boolean, 
      default: false 
    },
    axisType: { 
      type: String, 
      default: 'x', // 默认值为 'x'
      validator: value => ['x', 'y'].includes(value) 
    }
  },
  watch: {
    BarchartxyData(newdata) {
      if (newdata) {
        this.updateChart(newdata);
      }
    },
    barchartxyDownload(newVal) {
      if (newVal) {
        this.downloadChart();
      }
    },
    axisType(newVal) {
      if (this.BarchartxyData) {
        this.updateChart(this.BarchartxyData);
      }
    }
  },
  methods: {
    initChart() {
      this.chartInstance = echarts.init(this.$refs.BchartContainer);
      if (this.BarchartxyData) {
        this.updateChart(this.BarchartxyData);
      }
    },
    updateChart(data) {
      console.log('Updating chart with data:', data);
      const dimensions = data.columns
        .filter((column) => column.type === "dimension")
        .map((column) => column.code);

      const measures = data.columns
        .filter((column) => column.type === "measure")
        .map((column) => column.code);

      const filteredData = data.datas.filter(dataItem =>
        dimensions.every(dimension =>
          dataItem.hasOwnProperty(dimension) &&
          dataItem[dimension] !== undefined &&
          dataItem[dimension] !== null &&
          dataItem[dimension] !== ''
        )
      );

      const axisDataItem = filteredData.map(dataItem => dataItem[dimensions[0]]);
      const measureValues = measures.map(measure => ({
        name: measure,
        type: "bar",
        data: filteredData.map(dataItem => {
          const value = Number(dataItem[measure]);
          return !isNaN(value) ? value : null;
        })
      }));

      let options = {
        legend: {},
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: data.axisType === 'x' ? 'category' : 'value',
          data: data.axisType === 'x' ? axisDataItem : null,
          show: true,
          axisLabel: {
            rotate: 45,
            interval: 0,
            formatter: value => value.length > 6 ? `${value.slice(0, 6)}...` : value,
          }
        },
        yAxis: {
          type: data.axisType === 'y' ? 'category' : 'value',
          data: data.axisType === 'y' ? axisDataItem : null,
          show: true
        },
        series: data.axisType === 'y' 
          ? measureValues.map(item => {
              item.coordinateSystem = 'cartesian2d'; // 确保坐标系统正确设置
              return item;
            })
          : measureValues
      };

      this.chartInstance.setOption(options);
    },
    downloadChart() {
      const imgBase64 = this.chartInstance.getDataURL({
        type: 'png',
        pixelRatio: 2,
        backgroundColor: '#fff'
      });
      const link = document.createElement('a');
      link.href = imgBase64;
      link.download = 'chart.png';
      link.click();
    }
  },
  mounted() {
    this.initChart();
  }
};
</script>

<style scoped>
.chart-container {
  height: 400px;
}
</style>
