// import { ref, watch } from "vue"

// /** 项目标题 */
// const VITE_APP_TITLE = import.meta.env.VITE_APP_TITLE ?? "先知大模型机器人"

// /** 动态标题 */
// const dynamicTitle = ref<string>("")

// /** 设置标题 */
// const setTitle = (title?: string) => {
//   dynamicTitle.value = title ? `${VITE_APP_TITLE} | ${title}` : VITE_APP_TITLE
// }

// /** 监听标题变化 */
// watch(dynamicTitle, (value, oldValue) => {
//   if (document && value !== oldValue) {
//     document.title = value
//   }
// })

// export function useTitle() {
//   return { setTitle }
// }

import { ref, watch } from 'vue';

const ProjectTitle = ref<string>(import.meta.env.VITE_APP_TITLE || "先知大模型机器人");
const Pagetitle = ref<string>("");

const dynamicTitle = ref<string>("");

const setProjectTitle = (newTitle: string) => {
  ProjectTitle.value = newTitle;
  updateDynamicTitle();
};

const setTitle = (title: string) => {
  Pagetitle.value = title;
  updateDynamicTitle();
};

const updateDynamicTitle = () => {
  dynamicTitle.value = `${ProjectTitle.value}${Pagetitle.value ? ` | ${Pagetitle.value}` : ''}`;
};

watch(dynamicTitle, (value) => {
  if (document) {
    document.title = value;
  }
});

export function useTitle() {
  return { setTitle, setProjectTitle };
}