<template>
  <el-tooltip :disabled="isShowTooltip" :content="text" :placement="placement" :effect="effect">
    <div ref="ellipsis" class="ellipsis" @mouseover="onMouseOver">
      <span :ref="refName">{{ text }}</span>
    </div>
  </el-tooltip>
</template>

<script setup lang="ts">
import { ref, onMounted, getCurrentInstance } from 'vue'
const props = defineProps({
  placement: {
    type: String,
    default: 'top'
  },
  effect: {
    type: String,
    default: 'dark'
  },
  text: {
    type: String,
    required: true
  }
})
const isShowTooltip = ref(true)
const refName = ref(Date.now().toString())
let refDom:HTMLSpanElement
function onMouseOver() {
  const parentWidth = (refDom?.parentNode as HTMLDivElement)?.offsetWidth
  const contentWidth = refDom?.offsetWidth
  // 判断是否开启 tooltip 功能，如果溢出显示省略号，则子元素的宽度势必短于父元素的宽度
  if (contentWidth > parentWidth) {
      isShowTooltip.value = false
  } else {
      isShowTooltip.value = true
  }
}
onMounted(() => {
  const instance  = getCurrentInstance()
  if (instance) {
    refDom = <HTMLSpanElement>instance.refs[refName.value]
  }
})
</script>

<style lang="scss" scoped>
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>

