<template>
  <div v-if="errmsg !== ''" class="errmsg">{{ errmsg }}</div>
  <div ref="MchartContainer" class="map-chart-container"></div>
</template>
<script>
import * as echarts from "echarts";
import "echarts-extension-amap";

export default {
  props: {
    MapData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chinajson: "",
      errmsg: "",
      datas: [
        { name: "海门", value: 9 },
        { name: "鄂尔多斯", value: 12 },
        { name: "招远", value: 12 },
        { name: "舟山", value: 12 },
        { name: "齐齐哈尔", value: 14 },
        { name: "盐城", value: 15 },
        { name: "赤峰", value: 16 },
        { name: "青岛", value: 18 },
        { name: "乳山", value: 18 },
        { name: "金昌", value: 19 },
        { name: "泉州", value: 21 },
        { name: "莱西", value: 21 },
        { name: "日照", value: 21 },
        { name: "胶南", value: 22 },
        { name: "南通", value: 23 },
        { name: "拉萨", value: 24 },
        { name: "云浮", value: 24 },
        { name: "梅州", value: 25 },
        { name: "文登", value: 25 },
        { name: "上海", value: 25 },
        { name: "攀枝花", value: 25 },
        { name: "威海", value: 25 },
        { name: "承德", value: 25 },
        { name: "厦门", value: 26 },
        { name: "汕尾", value: 26 },
        { name: "潮州", value: 26 },
        { name: "丹东", value: 27 },
        { name: "太仓", value: 27 },
        { name: "曲靖", value: 27 },
        { name: "烟台", value: 28 },
        { name: "福州", value: 29 },
        { name: "瓦房店", value: 30 },
        { name: "即墨", value: 30 },
        { name: "抚顺", value: 31 },
        { name: "玉溪", value: 31 },
        { name: "张家口", value: 31 },
        { name: "阳泉", value: 31 },
        { name: "莱州", value: 32 },
        { name: "湖州", value: 32 },
        { name: "汕头", value: 32 },
        { name: "昆山", value: 33 },
        { name: "宁波", value: 33 },
        { name: "湛江", value: 33 },
        { name: "揭阳", value: 34 },
        { name: "荣成", value: 34 },
        { name: "连云港", value: 35 },
        { name: "葫芦岛", value: 35 },
        { name: "常熟", value: 36 },
        { name: "东莞", value: 36 },
        { name: "河源", value: 36 },
        { name: "淮安", value: 36 },
        { name: "泰州", value: 36 },
        { name: "南宁", value: 37 },
        { name: "营口", value: 37 },
        { name: "惠州", value: 37 },
        { name: "江阴", value: 37 },
        { name: "蓬莱", value: 37 },
        { name: "韶关", value: 38 },
        { name: "嘉峪关", value: 38 },
        { name: "广州", value: 38 },
        { name: "延安", value: 38 },
        { name: "太原", value: 39 },
        { name: "清远", value: 39 },
        { name: "中山", value: 39 },
        { name: "昆明", value: 39 },
        { name: "寿光", value: 40 },
        { name: "盘锦", value: 40 },
        { name: "长治", value: 41 },
        { name: "深圳", value: 41 },
        { name: "珠海", value: 42 },
        { name: "宿迁", value: 43 },
        { name: "咸阳", value: 43 },
        { name: "铜川", value: 44 },
        { name: "平度", value: 44 },
        { name: "佛山", value: 44 },
        { name: "海口", value: 44 },
        { name: "江门", value: 45 },
        { name: "章丘", value: 45 },
        { name: "肇庆", value: 46 },
        { name: "大连", value: 47 },
        { name: "临汾", value: 47 },
        { name: "吴江", value: 47 },
        { name: "石嘴山", value: 49 },
        { name: "沈阳", value: 50 },
        { name: "苏州", value: 50 },
        { name: "茂名", value: 50 },
        { name: "嘉兴", value: 51 },
        { name: "长春", value: 51 },
        { name: "胶州", value: 52 },
        { name: "银川", value: 52 },
        { name: "张家港", value: 52 },
        { name: "三门峡", value: 53 },
        { name: "锦州", value: 54 },
        { name: "南昌", value: 54 },
        { name: "柳州", value: 54 },
        { name: "三亚", value: 54 },
        { name: "自贡", value: 56 },
        { name: "吉林", value: 56 },
        { name: "阳江", value: 57 },
        { name: "泸州", value: 57 },
        { name: "西宁", value: 57 },
        { name: "宜宾", value: 58 },
        { name: "呼和浩特", value: 58 },
        { name: "成都", value: 58 },
        { name: "大同", value: 58 },
        { name: "镇江", value: 59 },
        { name: "桂林", value: 59 },
        { name: "张家界", value: 59 },
        { name: "宜兴", value: 59 },
        { name: "北海", value: 60 },
        { name: "西安", value: 61 },
        { name: "金坛", value: 62 },
        { name: "东营", value: 62 },
        { name: "牡丹江", value: 63 },
        { name: "遵义", value: 63 },
        { name: "绍兴", value: 63 },
        { name: "扬州", value: 64 },
        { name: "常州", value: 64 },
        { name: "潍坊", value: 65 },
        { name: "重庆", value: 66 },
        { name: "台州", value: 67 },
        { name: "南京", value: 67 },
        { name: "滨州", value: 70 },
        { name: "贵阳", value: 71 },
        { name: "无锡", value: 71 },
        { name: "本溪", value: 71 },
        { name: "克拉玛依", value: 72 },
        { name: "渭南", value: 72 },
        { name: "马鞍山", value: 72 },
        { name: "宝鸡", value: 72 },
        { name: "焦作", value: 75 },
        { name: "句容", value: 75 },
        { name: "北京", value: 79 },
        { name: "徐州", value: 79 },
        { name: "衡水", value: 80 },
        { name: "包头", value: 80 },
        { name: "绵阳", value: 80 },
        { name: "乌鲁木齐", value: 84 },
        { name: "枣庄", value: 84 },
        { name: "杭州", value: 84 },
        { name: "淄博", value: 85 },
        { name: "鞍山", value: 86 },
        { name: "溧阳", value: 86 },
        { name: "库尔勒", value: 86 },
        { name: "安阳", value: 90 },
        { name: "开封", value: 90 },
        { name: "济南", value: 92 },
        { name: "德阳", value: 93 },
        { name: "温州", value: 95 },
        { name: "九江", value: 96 },
        { name: "邯郸", value: 98 },
        { name: "临安", value: 99 },
        { name: "兰州", value: 99 },
        { name: "沧州", value: 100 },
        { name: "临沂", value: 103 },
        { name: "南充", value: 104 },
        { name: "天津", value: 105 },
        { name: "富阳", value: 106 },
        { name: "泰安", value: 112 },
        { name: "诸暨", value: 112 },
        { name: "郑州", value: 113 },
        { name: "哈尔滨", value: 114 },
        { name: "聊城", value: 116 },
        { name: "芜湖", value: 117 },
        { name: "唐山", value: 119 },
        { name: "平顶山", value: 119 },
        { name: "邢台", value: 119 },
        { name: "德州", value: 120 },
        { name: "济宁", value: 120 },
        { name: "荆州", value: 127 },
        { name: "宜昌", value: 130 },
        { name: "义乌", value: 132 },
        { name: "丽水", value: 133 },
        { name: "洛阳", value: 134 },
        { name: "秦皇岛", value: 136 },
        { name: "株洲", value: 143 },
        { name: "石家庄", value: 147 },
        { name: "莱芜", value: 148 },
        { name: "常德", value: 152 },
        { name: "保定", value: 153 },
        { name: "湘潭", value: 154 },
        { name: "金华", value: 157 },
        { name: "岳阳", value: 169 },
        { name: "长沙", value: 175 },
        { name: "衢州", value: 177 },
        { name: "廊坊", value: 193 },
        { name: "菏泽", value: 194 },
        { name: "合肥", value: 229 },
        { name: "武汉", value: 273 },
        { name: "大庆", value: 279 },
      ],
      geoCoordMap: {
        北京: [116.46, 39.92],
        上海: [121.48, 31.22],
        天津: [117.2, 39.13],
        重庆: [106.54, 29.59],
        石家庄: [114.48, 38.03],
        安国: [115.20, 38.24],
        保定: [115.48, 38.85],
        霸州: [116.24, 39.06],
        泊头: [116.34, 38.04],
        沧州: [116.52, 38.18],
        承德: [117.57, 40.59],
        定州: [115.00, 38.30],
        丰南: [118.06, 39.34],
        高碑店: [115.51, 39.20],
        蒿城: [114.50, 38.02],
        邯郸: [114.28, 36.36],
        河间: [116.05, 38.26],
        衡水: [115.42, 37.44],
        黄骅: [117.21, 38.21],
        晋州: [115.02, 38.02],
        冀州: [115.33, 37.34],
        廊坊: [116.42, 39.31],
        鹿泉: [114.19, 38.04],
        南宫: [115.23, 37.22],
        秦皇岛: [119.35, 39.55],
        任丘: [116.07, 38.42],
        三河: [117.04, 39.58],
        沙河: [114.30, 36.51],
        深州: [115.32, 38.01],
        唐山: [118.11, 39.36],
        武安: [114.11, 36.42],
        邢台: [114.30, 37.04],
        辛集: [115.12, 37.54],
        新乐: [114.41, 38.20],
        张家口: [114.53, 40.48],
        涿州: [115.59, 39.29],
        遵化: [117.58, 40.11],
        太原: [112.33, 37.54],
        长治: [113.06, 36.11],
        大同: [113.17, 40.06],
        高平: [112.55, 35.48],
        古交: [112.09, 37.54],
        河津: [110.41, 35.35],
        侯马: [111.21, 35.37],
        霍州: [111.42, 36.34],
        介休: [111.55, 37.02],
        晋城: [112.51, 35.30],
        临汾: [111.31, 36.05],
        潞城: [113.14, 36.21],
        朔州: [112.26, 39.19],
        孝义: [111.48, 37.08],
        忻州: [112.43, 38.24],
        阳泉: [113.34, 37.51],
        永济: [110.27, 34.52],
        原平: [112.42, 38.43],
        榆次: [112.43, 37.41],
        运城: [110.59, 35.02],
        沈阳: [123.25, 41.48],
        鞍山: [123.00, 41.07],
        北票: [120.47, 41.48],
        本溪: [123.46, 41.18],
        朝阳: [120.27, 41.34],
        大连: [121.36, 38.55],
        丹东: [124.22, 40.08],
        大石桥: [122.31, 40.37],
        东港: [124.08, 39.53],
        凤城: [124.02, 40.28],
        抚顺: [123.54, 41.51],
        阜新: [121.39, 42.01],
        盖州: [122.21,40.24],
        海城: [122.43,40.51],
        葫芦岛: [120.51,40.45],
        锦州: [121.09,41.07],
        开原: [124.02,42.32],
        辽阳: [123.12,41.16],
        凌海: [121.21,41.10],
        凌源: [119.22,41.14],
        盘锦: [122.03,41.07],
        普兰店: [121.58,39.23],
        铁法: [123.32,42.28],
        铁岭: [123.51, 42.18],
        瓦房店: [122.00, 39.37],
        兴城: [120.41, 40.37],
        新民: [122.49, 41.59],
        营口: [122.13, 40.39],
        庄河: [122.58, 39.41],
        长春: [125.19, 43.54],
        白城: [122.50, 43.38],
        白山: [126.26, 41.56],
        大安: [124.18, 45.30],
        德惠: [125.42, 44.32],
        敦化: [128.13, 43.22],
        公主岭: [124.49, 43.31],
        和龙: [129.00, 42.32],
        桦甸: [126.44, 42.58],
        珲春: [130.22, 42.52],
        集安: [126.11, 41.08],
        蛟河: [127.21, 43.42],
        吉林: [126.33, 43.52],
        九台: [125.51, 44.09],
        辽源: [125.09, 42.54],
        临江: [126.53, 41.49],
        龙井: [129.26, 42.46],
        舒兰: [126.57, 44.24],
        四平: [124.22, 43.10],
        松原: [124.49, 45.11],
        洮南: [122.47, 45.20],
        通化: [125.56, 41.43],
        图们: [129.51, 42.57],
        延吉: [129.30, 42.54],
        愉树: [126.32, 44.49],
        哈尔滨: [126.36, 45.44],
        阿城: [126.58, 45.32],
        安达: [125.18, 46.24],
        北安: [126.31, 48.15],
        大庆: [125.01, 46.36],
        富锦: [132.02, 47.15],
        海林: [129.21, 44.35],
        海伦: [126.57, 47.28],
        鹤岗: [130.16, 47.20],
        黑河: [127.29, 50.14],
        佳木斯: [130.22, 46.47],
        鸡西: [130.57, 45.17],
        密山: [131.50, 45.32],
        牡丹江: [129.36, 44.35],
        讷河: [124.51, 48.29],
        宁安: [129.28, 44.21],
        齐齐哈尔: [123.57, 47.20],
        七台河: [130.49, 45.48],
        双城: [126.15, 45.22],
        尚志: [127.55, 45.14],
        双鸭山: [131.11, 46.38],
        缓芬河: [131.11, 44.25],
        绶化: [126.59, 46.38],
        铁力: [128.01, 46.59],
        同江: [132.30, 47.39],
        五常: [127.11, 44.55],
        五大连池: [126.07, 48.38],
        伊春: [128.56, 47.42],
        筆东: [125.58, 46.04],
        杭州: [120.10, 30.16],
        慈溪: [121.15, 30.11],
        东阳: [120.14, 29.16],
        奉化: [121.24, 29.39],
        富阳: [119.57, 30.03],
        海宁: [120.42, 30.32],
        湖州: [120.06, 30.52],
        建德: [119.16, 29.29],
        江山: [118.37, 28.45],
        嘉兴: [120.45, 30.46],
        金华: [119.39, 29.07],
        兰溪: [119.28, 29.12],
        临海: [121.08, 28.51],
        丽水: [119.54, 28.27],
        龙泉: [119.08, 28.04],
        宁波: [121.33, 29.52],
        平湖: [121.01, 30.42],
        衢州: [118.52, 28.58],
        瑞安: [120.38, 27.48],
        上虞: [120.52, 30.01],
        绍兴: [120.34, 30.00],
        台州: [121.27, 28.41],
        桐乡: [120.32, 30.38],
        温岭: [121.21, 28.22],
        温州: [120.39, 28.01],
        义乌: [120.04, 29.18],
        萧山: [120.16, 30.09],
        乐清: [120.58, 28.08],
        余姚: [121.1, 30.02],
        余杭: [120.18, 30.26],
        永康: [120.01, 29.54],
        舟山: [122.06, 30.01],
        诸暨: [120.14, 29.43],
        长乐:[119.31,25.58],
        褔安:[119.39,27.06],
        褔青:[119.39,27.06],
        建瓯:[118.20,27.03],
        建阳: [118.07,27.21],
        晋江: [118.35,24.49],
        龙海: [117.48,24.26],
        龙岩: [117.01,25.06],
        南安: [118.23,24.57],
        南平: [118.10,26.38],
        宁德: [119.31,26.39],
        莆田: [119.01,24.26],
        泉州: [118.36,24.56],
        三明: [117.36,26.13],
        邵武: [117.29,27.20],
        石狮: [118.38,24.44],
        武夷: [118.02,27.46,],
        厦门: [118.06,24.27],
        永安: [117.23,25.58],
        漳平: [117.24,25.17,],
        漳州: [117.39,24.31],
        济南: [117.00,36.40],
        安丘: [119.12,36.25],
        滨州: [118.02,37.22],
        昌邑: [119.24,39.52],
        德州: [116.17,37.26],
        东营: [118.30,37.27],
        肥城: [116.46,36.14],
        高密: [119.44,36.22],
        菏泽: [115.26,35.14],
        胶南: [119.58,35.53],
        胶州: [120.00,36.17],
        即墨:[120.28,36.22],
        济宁:[116.33,35.23],
        莱芜:[117.40,36.12],
        莱西:[120.31,36.52],
        莱阳:[120.42,36.58],
        莱州:[119.57,37.10],
        乐陵:[117.12,37.44],
        聊城:[115.57,36.26],
        临清:[115.42,36.51],
        临沂:[118.20,35.03],
        龙曰:[120.21,37.39],
        蓬菜:[120.45,37.48],
        平度:[119.58,36.47],
        青岛:[120.18,36.03],
        青州:[118.28,36.42],
        曲阜:[116.58,35.36],
        曰照:[119.32,35.23],
        荣成:[122.25,37.10],
        乳山:[121.31,36.54],
        寿光:[118.44,36.53],
        泰安:[117.08,36.11],
        滕州:[117.09,35.06],
        潍坊:[119.06,36.43],
        威海:[122.07,37.31],
        文登:[122.03,37.12],
        新泰:[117.45,35.54],
        烟台:[121.24,37.32],
        兖州:[116.49,35.32],
        禹城:[116.39,36.56],
        栆庄:[117.33,34.52],
        章丘:[117.32,36.43],
        招远:[120.23,37.21],
        诸城:[119.24,35.59],
        淄博:[118.03,36.48],
        邹城:[116.58,35.24],
        郑州:[113.40,34.46],
        安阳:[114.21,36.06],
        长葛:[113.47,34.12],
        登封:[113.02,34.27],
        邓州:[112.05,32.42],
        巩义:[112.58,34.46],
        鹤壁:[114.11,35.54],
        辉县:[113.47,35.27],
        焦作:[113.12,35.14],
        济源:[112.35,35.04],
        开封:[114.21,34.47],
        灵宝:[110.52,34.31],
        林州:[113.49,36.03],
        漯河:[114.02,33.33],
        洛阳:[112.27,34.41],
        南阳:[112.32,33.00],
        平顶山:[113.17,33.44],
        濮阳:[115.01,35.44],
        汝州:[112.50,34.09],
        三门峡:[111.12,34.47],
        商丘:[115.38,34.26],
        卫辉:[114.03,35.24],
        舞钢:[113.30,33.17],
        顶城:[114.54,33.26],
        荥阳:[113.21,34.46],
        新密:[113.22,34.31],
        新乡:[113.52,35.18],
        信阳:[114.04,32.07],
        新郑:[113.43,34.24],
        许昌:[113.49,34.01],
        偃师:[112.47,34.43],
        义马:[111.55,34.43],
        禹州:[113.28,34.09],
        周曰:[114.38,33.37],
        驻马店:[114.01,32.58],
        武汉:[114.17,30.35],
        安陆:[113.41,31.15],
        当阳:[111.47,30.50],
        丹江曰:[108.30,32.33],
        大冶:[114.58,30.06],
        恩施:[109.29,30.16],
        鄂州:[114.52,30.23],
        广水:[113.48,31.37],
        洪湖:[113.27,29.48],
        黄石:[115.06,30.12],
        黄州:[114.52,30.27],
        荆门:[112.12,31.02],
        荆沙:[112.16,30.18],
        老河口:[111.40,32.23],
        利川:[108.56,30.18],
        麻城:[115.01,31.10],
        浦圻:[113.51,29.42],
        潜江:[112.53,30.26],
        石首:[112.24,29.43],
        十堰:[110.47,32.40],
        随州:[113.22,31.42],
        天门:[113.10,30.60],
        武穴:[115.33,29.51],
        襄樊:[112.08,32.02],
        咸宁:[114.17,29.53],
        仙桃:[113.27,30.22],
        孝感:[113.54,30.56],
        宜昌:[111.17,30.42],
        宜城:[112.15,31.42],
        应城:[113.33,30.57],
        枣阳:[112.44,32.07],
        枝城:[111.27,30.23],
        钟祥:[112.34,31.10],
        长沙:[112.59,28.12],
        常德:[111.51,29.02],
        郴州:[113.02,25.46],
        衡阳:[112.37,26.53],
        洪江:[109.59,27.07],
        怀化:[109.58,27.33],
        津市:[111.52,29.38],
        吉首:[109.43,28.18],
        耒阳: [112.51, 26.24],
        冷水滩: [111.35, 26.26],
        冷水江: [111.26, 27.42],
        涟源: [111.41, 27.41],
        醴陵: [113.30, 27.40],
        临湘: [113.27, 29.29],
        浏阳: [113.37, 28.09],
        娄底: [111.59, 27.44],
        汩罗: [113.03, 28.49],
        韶山: [112.29, 27.54],
        邵阳: [111.28, 27.14],
        武冈: [110.37, 26.43],
        湘潭: [112.53, 27.52],
        湘乡: [112.31, 27.44],
        益阳: [112.20, 28.36],
        永州: [111.37, 26.13],
        沅江: [112.22, 28.50],
        岳阳: [113.06, 29.22],
        张家界: [110.29, 29.08],
        株洲: [113.09, 27.51],
        资兴: [113.13, 25.58],
        广州: [113.14, 23.08],
        潮阳: [116.36, 23.16],
        潮州: [116.38, 23.40],
        澄海: [116.46,23.28],
        从化: [113.33, 23.33],
        东莞: [113.45,23.02],
        恩平: [112.19, 22.12],
        佛山: [113.06,23.02],
        高明: [112.50,22.53],
        高要: [112.26,23.02],
        高州: [110.50,21.54],
        鹤山: [112.57,22.46],
        河源: [114.41,23.43],
        花都: [113.12,23.23],
        化州: [110.37,21.39],
        惠阳: [114.28, 22.48],
        惠州: [114.22, 23.05],
        江门: [113.04, 22.35],
        揭阳: [116.21, 22.32],
        开平: [112.40, 22.22],
        乐昌: [113.21, 25.09],
        雷州: [110.04, 20.54],
        廉江: [110.17, 21.37],
        连州: [112.23, 24.48],
        罗定: [111.33, 22.46],
        茂名: [110.53, 21.40],
        梅州: [116.07, 24.19],
        南海: [113.09, 23.01],
        番禺: [113.22, 22.57],
        普宁: [116.10, 23.18],
        清远: [113.01, 23.42],
        三水: [112.52, 23.10],
        汕头: [116.41, 23.22],
        汕尾: [115.21, 22.47],
        韶关: [113.37, 24.48],
        深圳: [114.07, 22.33],
        顺德: [113.15, 22.50],
        四会: [112.41, 23.21],
        台山: [112.48, 22.15],
        吴川: [110.47, 21.26],
        新会: [113.01, 22.32],
        兴宁: [115.43, 24.09],
        阳春: [111.48, 22.10],
        阳江: [111.58, 21.50],
        英德: [113.22, 24.10],
        云浮: [112.02, 22.57],
        增城: [113.49, 23.18],
        湛江: [110.24, 21.11],
        肇庆: [112.27, 23.03],
        中山: [113.22, 22.31],
        珠海: [113.34, 22.17],
        海口: [110.20, 20.02],
        儋州: [109.34, 19.31],
        琼海: [110.28, 19.14],
        琼山: [110.21, 19.59],
        三亚: [109.31, 18.14],
        通什: [109.31, 18.46],
        成都: [104.04, 30.40],
        巴中: [106.43, 31.51],
        崇州: [103.40, 30.39],
        达川: [107.29, 31.14],
        德阳: [104.22, 31.09],
        都江堰: [103.37, 31.01],
        峨眉山: [103.29, 29.36],
        涪陵: [107.22, 29.42],
        广汉: [104.15, 30.58],
        广元: [105.51, 32.28],
        华蓥: [106.44, 30.26],
        简阳: [104.32, 30.24],
        江油: [104.42, 31.48],
        阆中: [105.58, 31.36],
        乐山: [103.44, 29.36],
        泸州: [105.24, 28.54],
        绵阳: [104.42, 31.30],
        南充: [106.04, 30.49],
        内江: [105.02, 29.36],
        攀枝花: [101.43, 26.34],
        彭州: [103.57, 30.59],
        巩崃: [103.28, 30.26],
        遂宁: [105.33, 30.31],
        万县: [108.21, 30.50],
        万源: [108.03, 32.03],
        西昌: [102.16, 27.54],
        雅安: [102.59, 29.59],
        宜宾: [104.34, 28.47],
        自贡: [104.46, 29.23],
        资阳: [104.38, 30.09],
        贵阳: [106.42, 26.35],
        安顺: [105.55, 26.14],
        毕节: [105.18, 27.18],
        赤水: [105.42, 28.34],
        都匀: [107.31, 26.15],
        凯里: [107.58, 26.35],
        六盘水: [104.50, 26.35],
        清镇: [106.27, 26.33],
        铜仁: [109.12, 27.43],
        兴义: [104.53, 25.05],
        遵义: [106.55, 27.42],
        昆明: [102.42, 25.04],
        保山: [99.10, 25.08],
        楚雄: [101.32, 25.01],
        大理: [100.13, 25.34],
        东川: [103.12, 26.06],
        个旧: [103.09, 23.21],
        景洪: [100.48, 23.01],
        开远: [103.13, 23.43],
        曲靖: [103.48, 25.30],
        瑞丽: [97.50, 24.00],
        思茅: [100.58, 22.48],
        畹町: [98.04, 24.06],
        宣威: [104.06, 26.13],
        玉溪: [102.32, 24.22],
        昭通: [103.42, 27.20],
        南昌: [115.55, 28.40],
        德兴: [117.35, 28.57],
        丰城: [115.48, 28.12],
        赣州: [114.56, 28.52],
        高安: [115.22, 28.25],
        吉安: [114.58, 27.07],
        景德镇: [117.13, 29.17],
        井冈山: [114.10, 26.34],
        九江: [115.58, 29.43],
        乐平: [117.08, 28.58],
        临川: [116.21, 27.59],
        萍乡: [113.50, 27.37],
        瑞昌: [115.38, 29.40],
        瑞金: [116.01, 25.53],
        上饶: [117.58, 25.27],
        新余: [114.56, 27.48],
        宜春: [114.23, 27.47],
        鹰潭: [117.03, 28.14],
        樟树: [115.32, 28.03],  
        西安: [108.57, 34.17],
        安康: [109.01, 32.41],
        宝鸡: [107.09, 34.22],
        韩城: [110.27, 35.28],
        汉中: [107.01, 33.04],
        华阴: [110.05, 34.34],
        商州: [109.57, 33.52],
        铜川: [109.07, 35.06],
        渭南: [109.30, 34.30],
        咸阳: [108.43, 34.20],
        兴平: [108.29, 34.18],
        延安: [109.28, 36.35],
        榆林: [109.47, 38.18],
        西宁: [101.48, 36.38],
        德令哈: [97.23, 37.22],
        格尔木: [94.55, 36.26],
        兰州: [103.51, 36.04],
        白银: [104.12, 36.33],
        敦煌: [94.41, 40.08],
        嘉峪关: [98.14, 39.48],
        金昌: [102.10, 38.28],
        酒泉: [98.31, 39.44],
        临夏: [103.12, 35.37],
        平凉: [106.40, 35.32],
        天水: [105.42, 34.37],
        武威: [102.39, 37.56],
        西峰: [107.40, 35.45],
        玉门: [97.35, 39.49],
        张掖: [100.26, 38.56],
        南宁: [108.19, 22.48],
        北海: [109.07, 21.28],
        北流: [110.21, 22.42],
        百色: [106.36, 23.54],
        防城港: [108.20, 21.37],
        贵港: [109.36, 23.06],
        桂林: [110.17, 25.17],
        桂平: [110.04, 23.22],
        河池: [108.03, 24.42],
        合山: [108.52, 23.47],
        柳州: [109.24, 23.19],
        赁祥: [106.44, 22.07],
        钦州: [108.37, 21.57],
        梧州: [111.20, 23.29],
        玉林: [110.09, 22.38],
        宜州: [108.40, 24.28],
        乌鲁木齐: [87.36, 43.45],
        阿克苏: [80.19, 41.09],
        阿勒泰: [88.12, 47.50],
        阿图什: [76.08, 39.42],
        博乐: [82.08, 44.57],
        昌吉: [87.18, 44.02],
        阜康: [87.58, 44.09],
        哈密: [93.28, 42.50],
        和田: [79.55, 37.09],
        克拉玛依: [84.51, 45.36],
        喀什: [75.59, 39.30],
        库尔勒: [86.07, 41.46],
        奎屯: [84.56, 44.27],
        石河子: [86.00, 44.18],
        塔城: [82.59, 46.46],
        吐鲁番: [89.11, 42.54],
        伊宁: [81.20, 43.55],
        呼和浩特: [111.41, 40.48],
        包头: [109.49, 40.39],
        赤峰: [118.58, 42.17],
        东胜: [109.59, 39.48],
        二连浩特: [111.58, 43.38],
        额尔古纳: [120.11, 50.13],
        丰镇: [113.09, 40.27],
        根河: [121.29,50.48 ],
        海拉尔: [119.39, 49.12],
        霍林郭勒: [119.38, 45.32],
        集宁: [113.06, 41.02],
        临河: [107.22, 40.46],
        满洲里: [117.23, 49.35],
        通辽: [122.16, 43.37],
        乌兰浩特: [122.03, 46.03],
        乌海: [106.48, 39.40],
        锡林浩特: [116.03, 43.57],
        牙克石: [120.40, 49.17],
        扎兰屯: [122.47, 48.00],
        拉萨: [91.08, 29.39],
        日客则: [29.16, 29.16],
        银川: [106.16, 38.27],
        青铜峡: [105.59, 37.56],
        石嘴山: [106.22, 39.02],
        吴忠: [106.11, 37.59],
        台湾: [121.5, 25.14],
        香港:[115.12, 21.23],
        澳门:[115.07, 21.33],
        合肥: [117.17, 31.52],
        安庆: [117.02, 30.31],
        蚌埠: [117.21, 32.56],
        毫州: [115.47, 33.52],
        巢湖: [117.52, 31.36],
        滁州: [118.18, 32.18],
        阜阳: [115.48, 32.54],
        贵池: [117.28, 30.39],
        淮北: [116.47, 33.57],
        淮南: [116.58, 32.37],
        黄山: [118.18, 29.43],
        界首: [115.21, 33.15],
        六安: [116.28, 31.44],
        马鞍山: [118.28, 31.43],
        明光: [117.58, 32.47],
        宿州: [116.58, 33.38],
        天长: [118.59, 32.41],
        铜陵: [117.48, 30.56],
        芜湖: [118.22, 31.19],
        宣州: [118.44, 30.57],
        南京: [118.46, 32.03],
        常熟: [120.43, 31.39],
        常州: [119.58, 31.47],
        丹阳: [119.32, 32.00],
        东台: [120.19, 32.51],
        高邮: [119.27, 32.47],
        海门: [121.09, 31.53],
        淮安: [119.09, 33.30],
        淮阴: [119.02, 33.36],
        江都: [119.32, 32.26],
        姜堰: [120.08, 32.34],
        江阴: [120.17, 31.54],
        靖江: [120.17, 32.02],
        金坛: [119.33, 31.46],
        昆山: [120.57, 31.23],
        连云港: [119.10, 34.36],
        // : [, ],
        // : [, ],
        // : [, ],







        
        海门: [121.15, 31.89],
        鄂尔多斯: [109.781327, 39.608266],
        招远: [120.38, 37.35],
        舟山: [122.207216, 29.985295],
        齐齐哈尔: [123.97, 47.33],
        盐城: [120.13, 33.38],
        赤峰: [118.87, 42.28],
        青岛: [120.33, 36.07],
        乳山: [121.52, 36.89],
        金昌: [102.188043, 38.520089],
        泉州: [118.58, 24.93],
        莱西: [120.53, 36.86],
        日照: [119.46, 35.42],
        胶南: [119.97, 35.88],
        南通: [121.05, 32.08],
        拉萨: [91.11, 29.97],
        云浮: [112.02, 22.93],
        梅州: [116.1, 24.55],
        文登: [122.05, 37.2],
        攀枝花: [101.718637, 26.582347],
        威海: [122.1, 37.5],
        厦门: [118.1, 24.46],
        汕尾: [115.375279, 22.786211],
        潮州: [116.63, 23.68],
        太仓: [121.1, 31.45],
        曲靖: [103.79, 25.51],
        烟台: [121.39, 37.52],
        福州: [119.3, 26.08],
        瓦房店: [121.979603, 39.627114],
        即墨: [120.45, 36.38],
        玉溪: [102.52, 24.35],
        莱州: [119.942327, 37.177017],
        湖州: [120.1, 30.86],
        汕头: [116.69, 23.39],
        昆山: [120.95, 31.39],
        宁波: [121.56, 29.86],
        湛江: [110.359377, 21.270708],
        揭阳: [116.35, 23.55],
        荣成: [122.41, 37.16],
        连云港: [119.16, 34.59],
        常熟: [120.74, 31.64],
        东莞: [113.75, 23.04],
        河源: [114.68, 23.73],
        淮安: [119.15, 33.5],
        泰州: [119.9, 32.49],
        南宁: [108.33, 22.84],
        惠州: [114.4, 23.09],
        江阴: [120.26, 31.91],
        蓬莱: [120.75, 37.8],
        韶关: [113.62, 24.84],
        嘉峪关: [98.289152, 39.77313],
        广州: [113.23, 23.16],
        延安: [109.47, 36.6],
        清远: [113.01, 23.7],
        中山: [113.38, 22.52],
        昆明: [102.73, 25.04],
        寿光: [118.73, 36.86],
        深圳: [114.07, 22.62],
        珠海: [113.52, 22.3],
        宿迁: [118.3, 33.96],
        咸阳: [108.72, 34.36],
        铜川: [109.11, 35.09],
        平度: [119.97, 36.77],
        佛山: [113.11, 23.05],
        海口: [110.35, 20.02],
        江门: [113.06, 22.61],
        章丘: [117.53, 36.72],
        肇庆: [112.44, 23.05],
        吴江: [120.63, 31.16],
        石嘴山: [106.39, 39.04],
        苏州: [120.62, 31.32],
        茂名: [110.88, 21.68],
        嘉兴: [120.76, 30.77],
        胶州: [120.03336, 36.264622],
        银川: [106.27, 38.47],
        张家港: [120.555821, 31.875428],
        三门峡: [111.19, 34.76],
        南昌: [115.89, 28.68],
        柳州: [109.4, 24.33],
        三亚: [109.511909, 18.252847],
        自贡: [104.778442, 29.33903],
        吉林: [126.57, 43.87],
        阳江: [111.95, 21.85],
        泸州: [105.39, 28.91],
        西宁: [101.74, 36.56],
        宜宾: [104.56, 29.77],
        呼和浩特: [111.65, 40.82],
        成都: [104.06, 30.67],
        镇江: [119.44, 32.2],
        桂林: [110.28, 25.29],
        张家界: [110.479191, 29.117096],
        宜兴: [119.82, 31.36],
        北海: [109.12, 21.49],
        西安: [108.95, 34.27],
        金坛: [119.56, 31.74],
        东营: [118.49, 37.46],
        牡丹江: [129.58, 44.6],
        遵义: [106.9, 27.7],
        绍兴: [120.58, 30.01],
        扬州: [119.42, 32.39],
        常州: [119.95, 31.79],
        潍坊: [119.1, 36.62],
        
        台州: [121.420757, 28.656386],
        南京: [118.78, 32.04],
        滨州: [118.03, 37.36],
        贵阳: [106.71, 26.57],
        无锡: [120.29, 31.59],
        克拉玛依: [84.77, 45.59],
        渭南: [109.5, 34.52],
        马鞍山: [118.48, 31.56],
        宝鸡: [107.15, 34.38],
        焦作: [113.21, 35.24],
        句容: [119.16, 31.95],
        徐州: [117.2, 34.26],
        包头: [110, 40.58],
        绵阳: [104.73, 31.48],
        乌鲁木齐: [87.68, 43.77],
        枣庄: [117.57, 34.86],
        杭州: [120.19, 30.26],
        淄博: [118.05, 36.78],
        溧阳: [119.48, 31.43],
        库尔勒: [86.06, 41.68],
        安阳: [114.35, 36.1],
        开封: [114.35, 34.79],
        济南: [117, 36.65],
        德阳: [104.37, 31.13],
        温州: [120.65, 28.01],
        九江: [115.97, 29.71],
        临安: [119.72, 30.23],
        兰州: [103.73, 36.03],
        临沂: [118.35, 35.05],
        南充: [106.110698, 30.837793],
        富阳: [119.95, 30.07],
        泰安: [117.13, 36.18],
        诸暨: [120.23, 29.71],
        郑州: [113.65, 34.76],
        哈尔滨: [126.63, 45.75],
        聊城: [115.97, 36.45],
        芜湖: [118.38, 31.33],
        平顶山: [113.29, 33.75],
        德州: [116.29, 37.45],
        济宁: [116.59, 35.38],
        荆州: [112.239741, 30.335165],
        宜昌: [111.3, 30.7],
        义乌: [120.06, 29.32],
        丽水: [119.92, 28.45],
        洛阳: [112.44, 34.7],
        株洲: [113.16, 27.83],
        莱芜: [117.67, 36.19],
        常德: [111.69, 29.05],
        湘潭: [112.91, 27.87],
        金华: [119.64, 29.12],
        岳阳: [113.09, 29.37],
        长沙: [113, 28.21],
        衢州: [118.88, 28.97],
        廊坊: [116.7, 39.53],
        菏泽: [115.480656, 35.23375],
        合肥: [117.27, 31.86],
        武汉: [114.31, 30.52],
        大庆: [125.03, 46.58],
      },
    };
  },
  mounted() {
    this.loadAMapScript(() => {
      console.log(this.MapData)
      this.createMap()
    });
    
    // this.initChart();
    // if (this.MapData) {
    //   this.updateChart(this.MapData);
    // }
  },
  watch: {
    MapData(newdata) {
      console.log(newdata);
      this.updateChart(newdata);
      // this.updateChart(newdata);
    },
  },
  methods: {
    initChart() {
      this.chartInstance = echarts.init(this.$refs.MchartContainer);
      var url = "";
      if (process.env.NODE_ENV === "production") {
        url = "./china.json";
      } else {
        url = "../../public/china.json";
      }
      fetch(url)
        .then((response) => response.json())
        .then((chinaJson) => {
          echarts.registerMap("chinaMap", chinaJson);
          this.renderChart();
        });
    },
    processData(data) {
      let msg = [];
      data.columns.forEach((item) => {
        if (item.type === "dimension") {
          msg.push("dimension");
        } else if (item.type === "measure") {
          msg.push("measure");
        }
      });

      const hasDimension = msg.includes("dimension");
      const hasMeasure = msg.includes("measure");

      if (!hasDimension || !hasMeasure) {
        this.errmsg = "很抱歉，由于数据缺失，请重新提问或者换个方式进行提问";
        return null;
      } else {
        this.errmsg = "";
      }

      const dimensionColumn = data.columns.find(
        (column) => column.type === "dimension"
      );
      const measureColumn = data.columns.find(
        (column) => column.type === "measure"
      );

      if (!dimensionColumn || !measureColumn) {
        this.errmsg = "很抱歉，由于数据缺失，请重新提问或者换个方式进行提问";
        return null;
      }

      const dimensionCode = dimensionColumn.code;
      const measureCode = measureColumn.code;

      return data.datas
        .filter((item) => item[dimensionCode] !== undefined)
        .map((item) => ({
          name: item[dimensionCode],
          value: item[measureCode],
        }));
    },
    renderChart(datasForChart) {
      const options = {
        tooltip: {
          trigger: "item",
        },
        geo: [
          {
            map: "chinaMap",
            roam: true,
            zoom: 10,
            show: false,
            emphasis: {
              itemStyle: {
                show: true, // 在高亮状态下显示标签
              },
              label: {
                show: false,
              },
            },
          },
        ],
        series: [
          {
            name: "中国地图",
            type: "map",
            map: "chinaMap",
            roam: true, // 允许滚动和缩放
            data: datasForChart,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  if (isNaN(params.value)) {
                    return params.name; // 当值为NaN时的显示文本
                  } else {
                    return params.name + params.value;
                  }
                },
              },
              emphasis: {
                show: true,
              },
            },
          },
        ],
      };

      this.chartInstance.setOption(options);
    },
    updateChart(data) {
      const datasForChart = this.processData(data);
      if (datasForChart) {
        this.renderChart(datasForChart);
      } else {
        console.warn("数据处理失败，无法更新图表");
      }
    },

    // updateChart(data) {
    //   var msg = [];
    //   data.columns.forEach((item) => {
    //     if (item.type == "dimension") {
    //       msg.push("dimension");
    //     } else if (item.type == "measure") {
    //       msg.push("measure");
    //     }
    //   });
    //   const hasDimension = msg.includes("dimension");
    //   const hasMeasure = msg.includes("measure");
    //   if (!hasDimension || !hasMeasure) {
    //     this.errmsg = "很抱歉，由于数据缺失，请重新提问或者换个方式进行提问";
    //     return;
    //   } else {
    //     this.errmsg = "";
    //   }

    //   const dimensionCode = data.columns.find(
    //     (column) => column.type === "dimension"
    //   ).code;
    //   const measureCode = data.columns.find(
    //     (column) => column.type === "measure"
    //   ).code;
    //   const datasForChart = data.datas
    //     .filter((item) => item[dimensionCode] !== undefined)
    //     .map((item) => ({
    //       name: item[dimensionCode],
    //       value: item[measureCode],
    //     }));

    //   const options = {
    //     tooltip: {
    //       trigger: "item",
    //     },
    //     geo: [
    //       {
    //         map: "chinaMap",
    //         roam: true,
    //         zoom: 10,
    //         show: false, //
    //         emphasis: {
    //           itemStyle: {
    //             show: true, // 在高亮状态下显示标签
    //           },
    //           label: {
    //             show: false,
    //           },
    //         },
    //       },
    //     ],

    //     series: [
    //       {
    //         name: "中国地图",
    //         type: "map",
    //         map: "chinaMap",
    //         roam: true, // 允许滚动和缩放
    //         data: datasForChart,
    //         label: {
    //           normal: {
    //             show: true,
    //             formatter: function (params) {
    //               if (isNaN(params.value)) {
    //                 return params.name; // 当值为NaN时的显示文本
    //               } else {
    //                 return params.name + params.value;
    //               }
    //             },
    //           },
    //           emphasis: {
    //             show: true,
    //           },
    //         },
    //       },
    //     ],
    //   };

    //   this.chartInstance.setOption(options);
    // },
    convertData(data) {
      var res = [];
      for (var i = 0; i < data.length; i++) {
        var geoCoord = this.geoCoordMap[data[i].name];
        if (geoCoord) {
          res.push({
            name: data[i].name,
            value: geoCoord.concat(data[i].value),
          });
        }
      }
      return res;
    },
    createMap() {
      var option = {
        amap: {
          viewMode: "3D",
          center: [108.39, 39.9],
          zoom: 4,
          resizeEnable: true,
          mapStyle: "amap://styles/normal",
          renderOnMoving: true,
          echartsLayerZIndex: 2000,
          echartsLayerInteractive: true,
          largeMode: false,
        },
        tooltip: {
          trigger: "item",
        },
        animation: true,
        series: [
          {
            name: "PM2.5",
            type: "scatter",
            coordinateSystem: "amap",
            data: this.convertData(this.datas),
            symbolSize: function (val) {
              return val[2] / 10;
            },
            encode: {
              value: 2,
            },
            label: {
              formatter: "{b}",
              position: "right",
              show: false,
            },
            itemStyle: {
              normal: {
                color: "#00c1de",
              },
            },
            emphasis: {
              label: {
                show: true,
              },
            },
          },
          {
            name: "Top 5",
            type: "effectScatter",
            coordinateSystem: "amap",
            data: this.convertData(
              this.datas
                .sort(function (a, b) {
                  return b.value - a.value;
                })
                .slice(0, 6)
            ),
            symbolSize: function (val) {
              return val[2] / 10;
            },
            encode: {
              value: 2,
            },
            showEffectOn: "render",
            rippleEffect: {
              brushType: "stroke",
            },
            hoverAnimation: true,
            label: {
              formatter: "{b}",
              position: "right",
              show: true,
            },
            itemStyle: {
              color: "#fff",
              shadowBlur: 10,
              shadowColor: "#333",
            },
            zlevel: 1,
          },
        ],
      };
      this.chartInstance = echarts.init(this.$refs.MchartContainer);
      this.chartInstance.setOption(option);
      var amapComponent = this.chartInstance.getModel().getComponent("amap");
      var amap = amapComponent.getAMap();
      amap.addControl(new AMap.Scale());
      amap.addControl(new AMap.ToolBar());
    },
    loadAMapScript(callback) {
      const script = document.createElement('script');
      script.src = "https://webapi.amap.com/maps?v=1.4.15&key=0385ee2ccf672af1c239fca7a29b0081"; 
      script.onload = callback;
      script.onerror = () => {
        this.errmsg = '加载高德地图失败，请检查网络或API密钥';
      };
      document.head.appendChild(script);
    },
  },
};
</script>

<style scoped>
.map-chart-container {
  height: 700px;
}
.errmsg {
  background-color: #f5f5f5;
  padding: 10px 7px;
  font-family: "Microsoft YaHei";
  border-radius: 5px;
}
</style>
