<template>
  <div v-if="errmsg !== ''" class="errmsg">{{ errmsg }}</div>
  <div ref="MchartContainer" class="map-chart-container"></div>
</template>
<script>
import * as echarts from "echarts";
import "echarts/extension/bmap/bmap";

export default {
  props: {
    MapData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chinajson: "",
      errmsg: "",
    };
  },
  // mounted() {
  //   this.initChart();
  //   if(this.MapData){
  //     this.updateChart(this.MapData)
  //   }
  // },
    async mounted() {
    await this.initChart(); 
    if (this.MapData) {
      this.updateChart(this.MapData);
    }
    },
  watch: {
    MapData(newdata) {
      console.log(newdata)
      this.updateChart(newdata);
    },
  },
  methods: {
    // initChart() {
    //   this.chartInstance = echarts.init(this.$refs.MchartContainer);
    //   var url = "";
    //   if (process.env.NODE_ENV === "production") {
    //     url = "./china.json";
    //   } else {
    //     url = "../../public/china.json";
    //   }
    //   fetch(url)
    //     .then((response) => response.json())
    //     .then((chinaJson) => {
    //       echarts.registerMap("chinaMap", chinaJson);
    //       this.renderChart();
    //     });
    // },
    async initChart() {
      this.chartInstance = echarts.init(this.$refs.MchartContainer);
      let url = "";
      if (process.env.NODE_ENV === "production") {
        url = "./china.json";
      } else {
        url = "../../public/china.json";
      }
      try {
        const response = await fetch(url);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const chinaJson = await response.json();
        echarts.registerMap("chinaMap", chinaJson);
        // 注意：这里不需要立即调用renderChart，因为updateChart会负责渲染
      } catch (error) {
        console.error('There was a problem with fetching or parsing the JSON', error);
        // 可能还需要处理错误状态，比如设置errmsg
      }
    },
    processData(data) {
      let msg = [];
      data.columns.forEach((item) => {
        if (item.type === "dimension") {
          msg.push("dimension");
        } else if (item.type === "measure") {
          msg.push("measure");
        }
      });

      const hasDimension = msg.includes("dimension");
      const hasMeasure = msg.includes("measure");

      if (!hasDimension || !hasMeasure) {
        this.errmsg = "很抱歉，由于数据缺失，请重新提问或者换个方式进行提问";
        return null;
      } else {
        this.errmsg = "";
      }

      const dimensionColumn = data.columns.find(
        (column) => column.type === "dimension"
      );
      const measureColumn = data.columns.find(
        (column) => column.type === "measure"
      );

      if (!dimensionColumn || !measureColumn) {
        this.errmsg = "很抱歉，由于数据缺失，请重新提问或者换个方式进行提问";
        return null;
      }

      const dimensionCode = dimensionColumn.code;
      const measureCode = measureColumn.code;

      return data.datas
        .filter((item) => item[dimensionCode] !== undefined)
        .map((item) => ({
          name: item[dimensionCode],
          value: item[measureCode],
        }));
    },
    renderChart(datasForChart) {
      const options = {
        tooltip: {
          trigger: "item",
        },
        geo: [
          {
            map: "chinaMap",
            roam: true,
            zoom: 10,
            show: false,
            emphasis: {
              itemStyle: {
                show: true, // 在高亮状态下显示标签
              },
              label: {
                show: false,
              },
            },
          },
        ],
        series: [
          {
            name: "中国地图",
            type: "map",
            map: "chinaMap",
            roam: true, // 允许滚动和缩放
            data: datasForChart,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  if (isNaN(params.value)) {
                    return params.name; // 当值为NaN时的显示文本
                  } else {
                    return params.name + params.value;
                  }
                },
              },
              emphasis: {
                show: true,
              },
            },
          },
        ],
      };

      this.chartInstance.setOption(options);
    },
    updateChart(data) {
      const datasForChart = this.processData(data);
      if (datasForChart) {
        this.renderChart(datasForChart);
      } else {
        console.warn("数据处理失败，无法更新图表");
      }
    },

    // updateChart(data) {
    //   var msg = [];
    //   data.columns.forEach((item) => {
    //     if (item.type == "dimension") {
    //       msg.push("dimension");
    //     } else if (item.type == "measure") {
    //       msg.push("measure");
    //     }
    //   });
    //   const hasDimension = msg.includes("dimension");
    //   const hasMeasure = msg.includes("measure");
    //   if (!hasDimension || !hasMeasure) {
    //     this.errmsg = "很抱歉，由于数据缺失，请重新提问或者换个方式进行提问";
    //     return;
    //   } else {
    //     this.errmsg = "";
    //   }

    //   const dimensionCode = data.columns.find(
    //     (column) => column.type === "dimension"
    //   ).code;
    //   const measureCode = data.columns.find(
    //     (column) => column.type === "measure"
    //   ).code;
    //   const datasForChart = data.datas
    //     .filter((item) => item[dimensionCode] !== undefined)
    //     .map((item) => ({
    //       name: item[dimensionCode],
    //       value: item[measureCode],
    //     }));

    //   const options = {
    //     tooltip: {
    //       trigger: "item",
    //     },
    //     geo: [
    //       {
    //         map: "chinaMap",
    //         roam: true,
    //         zoom: 10,
    //         show: false, //
    //         emphasis: {
    //           itemStyle: {
    //             show: true, // 在高亮状态下显示标签
    //           },
    //           label: {
    //             show: false,
    //           },
    //         },
    //       },
    //     ],

    //     series: [
    //       {
    //         name: "中国地图",
    //         type: "map",
    //         map: "chinaMap",
    //         roam: true, // 允许滚动和缩放
    //         data: datasForChart,
    //         label: {
    //           normal: {
    //             show: true,
    //             formatter: function (params) {
    //               if (isNaN(params.value)) {
    //                 return params.name; // 当值为NaN时的显示文本
    //               } else {
    //                 return params.name + params.value;
    //               }
    //             },
    //           },
    //           emphasis: {
    //             show: true,
    //           },
    //         },
    //       },
    //     ],
    //   };

    //   this.chartInstance.setOption(options);
    // },
  },
};
</script>

<style scoped lang="less">
@import "@/assets/css/common.less";
.map-chart-container {
  height: 700px;
}
.errmsg {
  background-color: @text-bgcolor;
  padding: 10px 7px;
  font-family: "Microsoft YaHei";
  border-radius: 5px;
}
</style>
