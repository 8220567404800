import request from '../axios/index.js';

// const handleRequest = (method, url, params = {}, type = 'json', wrapKey = null,baseURL) => {
//     let config = {
//         url,
//         method: method.toUpperCase(),
//         baseURL
//     };

//     if (method === 'POST') {
//         if (type === 'form') {
//             const formDatas = new FormData();
//             if (wrapKey) {
//                 formDatas.append(wrapKey, JSON.stringify(params));
//             } else {
//                 Object.keys(params).forEach(key => {
//                     if (typeof params[key] === 'object' && params[key] !== null) {
//                         formDatas.append(key, JSON.stringify(params[key]));
//                     } else {
//                         formDatas.append(key, params[key]);
//                     }
//                     // formDatas.append(key, params[key]);
//                 });
//             }
//             config.headers = {
//                 'Content-Type': 'multipart/form-data',
//             };
//             config.data = formDatas;
//         } else {
//             config.headers = {
//                 'Content-Type': 'application/json',
//             };
//             config.data = wrapKey ? JSON.stringify({ [wrapKey]: params }) : JSON.stringify(params);
//         }
//     } else if (method === 'GET') {
//         const queryParams = new URLSearchParams(params);
//         config.url += '?' + queryParams.toString();
//     } else {
//         throw new Error('');
//     }
//     return request(config);
// };
const handleRequest = (method, url, params = {}, type = 'json', wrapKey = null, baseURL) => {
    let config = {
        url,
        method: method.toUpperCase(),
        baseURL
    };

    if (method === 'POST') {
        if (type === 'form') {
            const formDatas = new FormData();
            
            // 文件对象
            const hasFile = Object.values(params).some(value => value instanceof Blob || value instanceof File);

            if (wrapKey && !hasFile) {
                formDatas.append(wrapKey, JSON.stringify(params));
            } else {
                Object.keys(params).forEach(key => {
                    const value = params[key];
                    if (value instanceof Blob || value instanceof File) {
                        formDatas.append(key, value);
                    } else if (typeof value === 'object' && value !== null) {
                        formDatas.append(key, JSON.stringify(value));
                    } else {
                        formDatas.append(key, value);
                    }
                });
            }

            config.headers = {
                'Content-Type': 'multipart/form-data',
            };
            config.data = formDatas;
        } else {
            config.headers = {
                'Content-Type': 'application/json',
            };
            config.data = wrapKey ? JSON.stringify({ [wrapKey]: params }) : JSON.stringify(params);
        }
    } else if (method === 'GET') {
        const queryParams = new URLSearchParams(params);
        config.url += '?' + queryParams.toString();
    } else {
        throw new Error('Unsupported HTTP method.');
    }

    return request(config);
};
export const getrecordList = () => {
    return handleRequest('GET', '/aiops/llm/list_my_quenstion_page');
};

export const addKmCategory = (formData) => {
    return handleRequest('POST', '/aiops/km/add_km_category', formData,'form');
};

export const updateKmCategory = (formData) => {
    return handleRequest('POST', '/aiops/km/update_km_category', formData,'form');
};

export const deleteKmCategory = (formData) => {
    return handleRequest('POST', '/aiops/km/delete_km_category', formData,'form');
};

export const getKmCategory=(formData)=>{
   return handleRequest('POST', '/aiops/km/list_km_category', formData,'form');
};

//知识维护管理
// 创建知识
export const addKm = (formData) => {
    return handleRequest('POST', '/aiops/km/add_km', formData,'form');
};

//知识列表
export const getkmPageList = (formData) => {
    return handleRequest('GET', '/aiops/km/list_km_page', formData);
};

//编辑知识
export const updateKm = (formData) => {
    return handleRequest('POST', '/aiops/km/update_km', formData,'form');
};

//删除知识
export const deleteKm = (formData) => {
    return handleRequest('POST', '/aiops/km/delete_km', formData,'form');
};


//查询知识标签维度
export const listKmViewType = (formData) => {
    return handleRequest('POST', '/aiops/km/list_km_view_type', formData,'form');
};

//新增知识标签维度
export const addKmViewType = (formData) => {
    return handleRequest('POST', '/aiops/km/add_km_view_type', formData,'form');
};
//编辑知识标签维度
export const updateKmViewType = (formData) => {
    return handleRequest('POST', '/aiops/km/update_km_view_type', formData,'form');
};

//删除知识标签维度
export const deleteKmViewType = (formData) => {
    return handleRequest('POST', '/aiops/km/delete_km_view_type', formData,'form');
};

//新增知识标签
export const addKmView = (formData) => {
    return handleRequest('POST', '/aiops/km/add_km_view', formData,'form');
};
//编辑知识标签
export const updateKmView = (formData) => {
    return handleRequest('POST', '/aiops/km/update_km_view', formData,'form');
};
//删除知识标签
export const deleteKmView = (formData) => {
    return handleRequest('POST', '/aiops/km/delete_km_view', formData,'form');
};
//查询知识标签值
export const listKmView = (formData) => {
    return handleRequest('POST', '/aiops/km/list_km_view', formData,'form');
};

// 知识管理同步
export const sync_km = (formData) => {
    // const urlWithParams = `/aiops/km/sync_km`;
    // return handleRequest('GET', urlWithParams);
    return handleRequest('GET', '/aiops/km/sync_km',formData);
};

// 知识管理停用
export const unsync_km = (formData) => {
    return handleRequest('GET', '/aiops/km/unsync_km',formData);
};




// 数据管理=>数据模型=>数据对象

// 标签对象树形列表
export const tagListTree = (formData) => {
    return handleRequest('POST', '/tags/model/list_data_tag_model_page', formData,'form');
};
//标签对象树形删除
export const tagListTreeDelete = (formData) => {
    return handleRequest('POST', '/tags/model/delete_data_tag_model', formData,);
};
//标签对象树形对象新增
export const tagListAdd = (formData) => {
    return handleRequest('POST', '/tags/model/add_data_tag_model', formData,'form');
};
//标签对象树形对象编辑
export const tagListEdit = (formData) => {
    return handleRequest('POST', '/tags/model/update_data_tag_model', formData,'form');
};

//标签对象列表
export const tagList = (formData) => {
    return handleRequest('POST', '/tags/model/list_data_tag_model_tag_page', formData,'form');
};
//标签对象列表操作删除
export const deleteDataDagModelTag = (formData) => {
    return handleRequest('POST', '/tags/model/delete_data_tag_model_tag', formData,'form');
};
//标签对象列表操作执行
export const manualExecute = (formData) => {
    return handleRequest('GET', 'modules/server/service/manual_execute', formData,);
};
//标签对象列表操作停用
export const disableDataDagModelTag = (formData) => {
    return handleRequest('GET', '/tags/model/disable_data_tag_model_tag', formData,);
};
//标签对象列表操作启动
export const enableDataDagModelTag = (formData) => {
    return handleRequest('GET', '/tags/model/enable_data_tag_model_tag', formData,);
};
//标签对象列表操作编辑
export const updateDataDagModelTag = (formData) => {
    return handleRequest('POST', '/tags/model/update_data_tag_model_tag', formData,'form');
};
//标签对象列表新增
export const addDataDagModelTag = (formData) => {
    return handleRequest('POST', '/tags/model/add_data_tag_model_tag', formData,'form');
};



//标签对象列表编辑上级分组
export const superiorGroup = (formData) => {
    return handleRequest('GET','/tags/model/list_data_tag_model_tag', formData);
};
//标签对象列表编辑数据库连接
export const databaseConnection = (formData) => {
    return handleRequest('GET','modules/server/service/list_service', formData);
};
//标签对象列表编辑数据库字段
export const databaseFields = (formData) => {
    //表单传递dataSourceName: demo_center   tableName: employee

    return handleRequest('POST','jdbc/meta/get_table_meta', formData,'form');
};
//编辑数据库表
export const databaseTables = (formData) => {
    //表单传递dataSourceName: dm7_test
    return handleRequest('POST','jdbc/meta/list_schema', formData,'form');
};
//新增库表导入
export const TagListService = (formData) => {
    // editorType=datasource
    return handleRequest('GET','modules/server/service/list_service', formData,);
};
//库表查询
export const TagListTableMeta = (formData) => {
    return handleRequest('GET','jdbc/meta/list_table_meta', formData,);
};
//收藏
export const addTagFavorite = (formData) => {
    return handleRequest('POST','tags/model/tag/add_tag_favorite', formData,'form');
};
//授权撤销
export const deleteTagPermission = (formData) => {
    // permissionId表单
    return handleRequest('POST','tags/model/tag/delete_tag_permission', formData,'form');
};

//用户授权
export const listUserPage = (formData) => {
    // start ,limit
    return handleRequest('GET','modules/rbac/user/list_user_page', formData,);
};
//用户确认授权
export const addTagPermission = (formData) => {
    return handleRequest('POST','tags/model/tag/add_tag_permission', formData,'form');
};

//应用授权
export const listTagPermissionPage = (formData) => {
    // permission=manager&withGrantUserName=true&withResource=true&tagCode=employee_TAG_MF_00000160&start=0&limit=15
    return handleRequest('GET','tags/model/tag/list_tag_permission_page', formData,);
};

//库表导入下一步
export const getNextCounterValue = (formData) => {
    return handleRequest('GET','modules/config/get_next_counter_value', formData,);
};
//库表导入确认
export const storeDataTagModelTag = (formData) => {
    return handleRequest('POST','tags/model/store_data_tag_model_tag', formData,'form');
};
export const dialogueScreen = (formData) => {
    return handleRequest('POST', '/dialogue/aiops/data/json_query', formData, 'form', 'json');
};




// 数据指标
/*指标数据元素*/
// 指标分类列表
export const getMeasureListKpi = (formData) => {
    return handleRequest('GET','/kpi/measure/list_kpi_measure',formData,'json',null,import.meta.env.VITE_BASE_NEWAPI);
};
// 指标分类编辑
export const updateKpiMeasure= (formData,time) => {
    return handleRequest('POST',`/kpi/measure/update_kpi_measure?_ts=${time}`,formData,'form',null,import.meta.env.VITE_BASE_NEWAPI);
};
// 指标分类新增
export const addKpiMeasure= (formData,time) => {
    return handleRequest('POST',`/kpi/measure/add_kpi_measure?_ts=${time}`,formData,'form',null,import.meta.env.VITE_BASE_NEWAPI);
};

// 指标分类删除
export const delKpiMeasure= (formData,time) => {
    return handleRequest('POST',`/kpi/measure/delete_kpi_measure?_ts=${time}`,formData,'form',null,import.meta.env.VITE_BASE_NEWAPI);
};

// 指标分类标识生成
export const getMeasureNextCounterValue= (formData) => {
    return handleRequest('GET',`/modules/config/get_next_counter_value`,formData,'json',null,import.meta.env.VITE_BASE_NEWAPI);
};

// 指标列表
export const getMeasureListPageKpi = (formData) => {
    return handleRequest('GET','/kpi/measure/list_kpi_measure_page',formData,'json',null,import.meta.env.VITE_BASE_NEWAPI);
};

// 指标删除
export const deleteKpiMeasure= (formData,time) => {
    return handleRequest('POST',`/kpi/measure/delete_kpi_measure?_ts=${time}`,formData,'form',null,import.meta.env.VITE_BASE_NEWAPI);
};

// 指标口径
export const getListKpiMeasureSpec = (formData) => {
    return handleRequest('GET','/kpi/measure/list_kpi_measure_spec',formData,'json',null,import.meta.env.VITE_BASE_NEWAPI);
};
// 指标新增
export const addKpiMeasureList= (formData,time) => {
    return handleRequest('POST',`/kpi/measure/add_kpi_measure?_ts=${time}`,formData,'form',null,import.meta.env.VITE_BASE_NEWAPI);
};
//数据库分类列表
export const getListService = (formData) => {
    return handleRequest('GET','/modules/server/service/list_service',formData,'json',null,import.meta.env.VITE_BASE_NEWAPI);
};
//数据库表类型列表
export const listSchema= (formData,time) => {
    return handleRequest('POST',`/jdbc/meta/list_schema?_ts=${time}`,formData,'form',null,import.meta.env.VITE_BASE_NEWAPI);
};
//数据库表列表
export const listTableMeta= (formData) => {
    return handleRequest('GET','/jdbc/meta/list_table_meta',formData,'json',null,import.meta.env.VITE_BASE_NEWAPI);
};
//计算字段
export const getTableMeta= (formData,time) => {
    return handleRequest('POST',`/jdbc/meta/get_table_meta?_ts=${time}`,formData,'form',null,import.meta.env.VITE_BASE_NEWAPI);
};

// 数据纬度列表
export const getDimensionListPageKpi = (formData) => {
    return handleRequest('GET','/kpi/dimension/list_kpi_dimension',formData,'json',null,import.meta.env.VITE_BASE_NEWAPI);
};

//  数据纬度分类新增
export const addKpiDimension= (formData,time) => {
    return handleRequest('POST',`/kpi/dimension/add_kpi_dimension?_ts=${time}`,formData,'form',null,import.meta.env.VITE_BASE_NEWAPI);
};

//  数据纬度分类更新
export const updateKpiDimension= (formData,time) => {
    return handleRequest('POST',`/kpi/dimension/update_kpi_dimension?_ts=${time}`,formData,'form',null,import.meta.env.VITE_BASE_NEWAPI);
};

//  数据纬度分类删除
export const delKpiDimension= (formData,time) => {
    return handleRequest('POST',`/kpi/dimension/delete_kpi_dimension?_ts=${time}`,formData,'form',null,import.meta.env.VITE_BASE_NEWAPI);
};


export const uuid=(formData)=>{
    // counterName=KM
    return handleRequest('GET','/modules/config/get_next_counter_value',formData);
};
export const decomposeSplitKm=(formData)=>{
    //kmCode
    return handleRequest('GET','/aiops/km/split_km',formData);
};
//知识条目列表
export const decomposeListKmItemPage=(formData)=>{
    //kmCode
    return handleRequest('GET','/aiops/km/list_km_item_page',formData);
};
//知识分布
export const decomposeSyncKkmItem=(formData)=>{
    //itemCode
    return handleRequest('GET','/aiops/km/sync_km_item',formData);
};
export const amendmentstoreKmitem=(formData)=>{
    return handleRequest('POST','/aiops/km/store_km_item',formData,'form');
};





// export const getLoadOnlyoffice = (formData) => {
//     return handleRequest('GET','/only/office/get_file_operated_info',formData,'json',null,);
// };