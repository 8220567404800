// 某某研究院专用
<template>
  <div>  
    <el-dialog title="凭证管理" v-model="dialogVisble" width="60%" height="70%">
    <div style="display: flex; justify-content: center;"> 
      <iframe id="sshframe" :src="sshSrc" width="100%" :height="frameHeight" frameborder="0">

      </iframe>
    </div>
    
  </el-dialog>
  </div>  
  <div class="large-model-content">
    <div class="websocket-area">
      <!-- <div class="left-region">
        <leftside @characters="issue" @empty="cleanup"></leftside>
        <knowledgeLeft
          v-show="panelType == '知识'"
          @dialogueType="dialogueType"
        ></knowledgeLeft>
      </div> -->
      <div class="edit-area">
        <div
          class="content-area dialog-scroll"
          :class="{ column: responseHtmlShow }"
          id="dialog_scroll"
        >
          <div class="initial" v-if="initialization">
            <p class="greeting">
              <img :src="robotImg" alt="" class="robotpng" />
              <br />
              你好，我是{{ sceneMsg.sceneName }}
            </p>
            <p class="skill">
              {{ sceneMsg.sceneDesc }}
            </p>
          </div>
          <div class="chat-history switch" v-else>
            <div
              v-for="(item, index) in chathistory"
              :key="`${item.id}_${item.currenttype}`"
            >
              <kmTemplate
                v-if="
                  item.sender == 'other' &&
                  item.type == 'html' &&
                  item.currenttype == 'KM'
                "
                :messageData="item"
                :messagekey="`${index}_${item.id}`"
                :messageAnmation="turnanimation"
                :loadingvalue="loadingIndex"
                @updateShare="updateParentChart"
                :shared-data="sharedData"
                @reload="reload(index)"
                :historyList="chathistory"
              ></kmTemplate>
              <currencyTemplate
                v-if="
                  item.sender == 'other' &&
                  item.type == 'html' &&
                  item.currenttype !== 'KM' &&
                  item.currenttype !== 'Chart'
                "
                :messageData="item"
                :messagekey="`${index}_${item.id}`"
                :messageAnmation="turnanimation"
                :loadingvalue="loadingIndex"
                @updateShare="updateParentChart"
                :shared-data="sharedData"
                @reload="reload(index)"
                :historyList="chathistory"
              ></currencyTemplate>
              <div class="message self" v-else-if="item.sender == 'self'">
                <span class="message-time">{{ item.time }}</span>
                <img :src="peopleImg" alt="" class="self-img participants" />
                <div class="text">{{ item.text }}</div>
              </div>
              <div class="message other" v-else-if="item.sender == 'fault'">
                <span class="message-time">{{ item.time }}</span>
                <img :src="robotImg" alt="" class="other-img participants" />
                <div class="text">
                  {{ item.text }}
                  <div class="operate">
                    <img
                      @click="reload"
                      :src="loadingImg"
                      class="self-img participants"
                      alt=""
                      title="重新加载"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DynamicHTML
          :class="{ columnsty: responseHtmlShow }"
          :html="rHtmlScript"
          :inlineScripts="[]"
          @submit="handleChildSubmit"
        />
        <div class="stopButton" v-show="stopButton">
          <el-button @click="interrupt" class="stop">
            <img :src="stopImg" class="participants stopimg" alt="" />
            停止回答</el-button
          >
        </div>
        <div class="edit-box" :class="{ minedit: stopButton }" style="height:400px">
          <div class="edit-input">
            <div class="edit-content">
              <div class="ant-tabs">
                <textarea
                  ref="myTextarea"
                  v-model="textareaText"
                  placeholder="在这里输入，实现IT智能运维"
                  class="input-area"
                  :disabled="!sending"
                  @keyup.enter="handleEnterKey"
                  style="
                    width: 100%;
                    border: none;
                    height: 60px;
                    resize: none;
                    min-height: 200px;
                    background-color: transparent;
                    margin-top: 45px;
                    height: 300px
                  "
                ></textarea>
                <div class="functionArea">
                  <!-- 场景 -->
                  <div class="scene">
                    场景选择:
                    <el-select
                      class="custom-select"
                      v-model="scenevalue"
                      placeholder="场景选择"
                      style="width: 170px"
                      @change="handleSceneChange"
                    >
                      <el-option
                        v-for="item in sceneArray"
                        :key="item.sceneCode"
                        :label="item.sceneName"
                        :value="item"
                      />
                    </el-select>
                  </div>
                  <div class="scene" style="margin-right:10px;">
                    <div class="voiceText" @click="clearDiaglog">
                      <img :src="dialogue1" style="width:20px;height:20px;vertical-align: middle;background-size: cover;" alt="" />
                      <span style="color:blue;cursor: pointer;margin-left: 4px">清空对话</span>
                    </div>
                  </div>
                  
                  <div class="scene" style="margin-right:10px;">
                    <div class="voiceText" @click="sshManage">
                      <img :src="peopleImg" style="width:20px;height:20px;vertical-align: middle;background-size: cover;" alt="" />
                      <span style="color:blue;cursor: pointer;margin-left: 4px">凭证管理</span>
                    </div>
                  </div>
                  
                  <div class="VoicePackage" style="display:none">
                    <div class="voiceText" @click="voice">
                      <vue-media-recorder
                        v-if="isMicrophoneAvailable"
                        v-model="audioData"
                        @start="handleStart"
                        @stop="handleStop"
                      ></vue-media-recorder>
                      <img :src="voiceImg" class="voicepng" alt="" />
                      {{ voiceText }}
                    </div>
                  </div>
                </div>
                <!-- 禁用 -->
                <img
                  :src="disabledredImg"
                  class="disabled large-model-cpng"
                  v-show="!sending"
                  alt=""
                />
                <img
                  :src="sendImg"
                  class="sendpng large-model-cpng"
                  @click="doSend"
                  v-show="disable == false"
                  alt=""
                />
                <img
                  :src="prohibitImg"
                  class="sendpng large-model-cpng"
                  v-show="disable == true"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, nextTick, watch, onMounted } from "vue";
import axios from "axios";
import { ElMessage } from "element-plus";

import VueMediaRecorder from "vue-media-recorder";
import currencyTemplate from "@/components/currencyTemplate.vue";
import kmTemplate from "@/components/kmTemplate.vue";
import DynamicHTML from "@/components/DynamicHTML.vue";

import robotImg from "@/assets/img/robot.png";
import peopleImg from "@/assets/img/people.png";
import sendImg from "@/assets/img/send.png";
import prohibitImg from "@/assets/img/prohibit.png";
import voiceImg from "@/assets/img/voice.png";
import dialogue1 from "@/assets/img/dialogue1.png";
import disabledredImg from "@/assets/img/disabledred.png";
import stopImg from "@/assets/img/stop.png";

let mediaRecorder: MediaRecorder | null = null;
const myTextarea = ref(null);
const dialogVisble = ref(false)
const initialization = ref(true); //场景标题
const chathistory = ref([]); //聊天记录
const textareaText = ref(""); //输入框内容区域
const conversationId = ref(""); //会话id
const disable = ref(false); //输入框是否禁止输入
const sessionID = ref(""); //当前会话id
const obsoleteSessionID = ref(""); //上一个会话id
const voiceText = ref("语音输入");
const audioChunks = ref([]); //音频
const audioData = ref(null); //音频
const isMicrophoneAvailable = ref(false); //音频
const VoicePromptsTetx = ref(""); //音频异常提示语
const sampleRate = ref(""); //音频转换频率
const current = ref(false); //数据流是否返回最终回答状态；
const thinking = ref(); //展开索引
const responseHtml = ref(""); //交互窗口
const rHtmlScript = ref([]); //交互窗口
const responseHtmlShow = ref(false); //交互弹窗
const turnanimation = ref(false); //转动动画
const sceneArray = ref([]); //场景
const sceneMsg = ref(""); //场景
const scenevalue = ref(""); //场景
const loadingIndex = ref(""); //加载loading
const sending = ref(true); //是否禁止发送
const stopReading = ref(false); //结束上一个会话
const stopButton = ref(false); //停止回答按钮
const diaglogVis = ref(false); //停止回答按钮
const sshSrc = ref("")
const frameHeight = ref("200px")

const paramsData = ref({
  history: [],
  model_name: "qianfan-api",
  prompt_name: "",
  query: "",
  stream: true,
  temperature: 0.1,
  scene_code: "",
});
const currenttype = ref(""); //当前返回类型
const currenttypeKM = ref(false); // km类型
const sharedData = ref({
  launch: "",
});
const loaDingIndex = ref("0"); //loading索引
const allAnswers = ref([]);

const menuClassificationMsg = ref({}); //左侧菜单；
const panelType = ref("");
const dialogueTypeValue = ref(""); //知识对话
const deleteIndex = ref(); //重新加载的索引
const recognition = ref();

const speechUtterance = ref(null);

let analyser = null;
let dataArray = null;
let sourceNode = null;
let silenceStart = null; 
const socket = ref(null);
const Recorder=ref({
	type:"pcm",
	bitRate:16,
	sampleRate:16000,
	// onProcess:recProcess
});
const isRec = ref(false);

onMounted(() => {
  // console.log(createSpeechRecognition())
  // speechRecognition.value = createSpeechRecognition();
  // console.log(speechRecognition.value)
  getscene();
});

//共享数据
const updateParentChart = (data) => {
  sharedData.value = data;
};
//左侧子组件点击问题传递到父组件的信息
const issue = (data) => {
  textareaText.value = data;
  nextTick(() => {
    if (myTextarea.value) {
      myTextarea.value.focus();
    }
  });
};
//清空对话
const cleanup = (data) => {
  doNewConversation();
};
//知识类型对话选择列表
const dialogueType = (data) => {
  dialogueTypeValue.value = data.name;
};

//新建对话
const doNewConversation = () => {
  stopReading.value = true; //结束上一个会话
  initialization.value = true;
  disable.value = false;
  stopButton.value = false;
  chathistory.value = [];
  conversationId.value = "";
  textareaText.value = "";
  sending.value = true;
  //每次会话保证都是新的uuid
  sessionID.value = uuid();
  paramsData.value.history = [];
  //防止将上一次会话录制的音频带入到本次sending
  if (mediaRecorder && mediaRecorder.state === "recording") {
    mediaRecorder.stop();
    mediaRecorder.addEventListener("stop", async (event: BlobEvent) => {
      mediaRecorder = null;
      voiceText.value = "语音输入";
      audioChunks.value.length = 0;
    });
  }
};

//发送信息
const doSend = () => {
  if (disable.value) {
    return;
  }
  stopButton.value = true;
  sending.value = false;
  if (stopReading.value) {
    stopReading.value = false;
  }
  if (textareaText.value.trim() !== "") {
    responseHtmlShow.value = false;
    initialization.value = false;
    rHtmlScript.value = "";
    // chathistory.value.push(Data);
    obsoleteSessionID.value = sessionID.value;
    setChathistory("finish");
    setChathistory("self", textareaText.value.trim());
    createData();
    // currentsessionIndex.value = ++currentsessionIndex.value;
    loadingIndex.value = chathistory.value.length;
    textareaText.value = "";
  }
};

const createData = (msg) => {
  obsoleteSessionID.value = sessionID.value;
  if (!conversationId.value) {
    conversationId.value = uuid();
  }
  let input_data = "";
  let request = {
    prompt_name: "default",
  };
  if (msg) {
    input_data = msg.text;
    chathistory.value.pop();
  } else {
    input_data = textareaText.value;
  }
  paramsData.value.prompt_name = request.prompt_name;
  paramsData.value.query = input_data;
  paramsData.value.scene_code = sceneMsg.value.sceneCode;
  if (chathistory.value.length > 1) {
    let selfMessages = chathistory.value.filter(
      (message) => message.sender === "self"
    );
    let otherMessages = chathistory.value.filter(
      (message) => message.sender === "other"
    );
    if (selfMessages.length > 1 && otherMessages.length > 0) {
      let secondLastSelfMessage = selfMessages[selfMessages.length - 2];
      let secondLastOtherMessage = otherMessages[otherMessages.length - 1];
      let historyObj = {
        role: "user",
        content: secondLastSelfMessage.text,
      };
      let otherhistoryObj = {
        role: "assistant",
        content:
          secondLastOtherMessage.text == "思考中.."
            ? ""
            : secondLastOtherMessage.text,
      };
      paramsData.value.history.push(historyObj, otherhistoryObj);
    }
  }
  if(paramsData.value.scene_code == "km_aiops"){
    // setChathistory("other", "这个是测试", "html", true);
    //知识库直接通过接口返回
    stopButton.value = false;
    getKM(paramsData.value);
  } else {
    getMessagesHistory(paramsData.value, true);
  }
};

async function getKM(question) {
  const url = "/kmchat/km";
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({query: question}),
  });
  if (response.ok) {
    // {"success":true,"msg":{"kmCode":"","kmTitle":{"history":[],"model_name":"qianfan-api","prompt_name":"default","query":"内存低怎么办\n","stream":true,"temperature":0.1,"scene_code":"km_aiops"},"kmDesc":{"history":[],"model_name":"qianfan-api","prompt_name":"default","query":"内存低怎么办\n","stream":true,"temperature":0.1,"scene_code":"km_aiops"},"kmContent":"当服务器内存低时，可以采取以下措施：\n\n1. 增加物理内存或虚拟分配更多内存，如有必要，可以利用swap空间扩展虚拟内存。\n2. 使用`free -m`命令监控内存使用情况，找出内存占用过高的进程并结束它们，例如使用`top`命令。\n3. 如果内存缓存占比过高，可通过`/proc/sys/vm/drop_caches`命令手动清除缓存，但请注意这可能会影响系统性能。\n\n如果服务器采集不到SNMP数据，检查网络连接、SNMP版本匹配以及DNS设置。\n\n如果服务器ICMP ping响应时间过长，可能是由于系统资源不足、网络带宽不足或DNS设置不正确，分别针对这些原因进行优化。"}}*/ */
     
    const result = await response.json()
    if (result.success) {
      currenttypeKM.value = "KM";
      setChathistory("other", result.msg.kmContent, "html", false);
      setChathistory("finish");
      sending.value = true;
      stopButton.value = false;
  }
    console.log(JSON.stringify(result))
  }
}

async function getMessagesHistory(historyLen, contentInExpander = false) {
  const url = "/chat/agent_chat";
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(historyLen),
    });

    if (!response.ok) {
      throw new Error(`${response.status}`);
    }

    // 处理分块传输编码的响应
    return handleChunkedResponse(response);
  } catch (error) {
    console.error(error);
    throw error; // 错误信息
  }
}

async function handleChunkedResponse(response) {
  const reader = response.body.getReader();

  const chunks = [];
  let bytesReceived = 0;

  const processChunk = ({ value, done }) => {
    // 全部接收完毕或手动停止会话，
    if (done || stopReading.value) {
      deleteIndex.value = "";
      const combinedUint8Array = new Uint8Array(
        chunks.reduce((acc, cur) => acc + cur.length, 0)
      );
      let offset = 0;
      for (const chunk of chunks) {
        combinedUint8Array.set(chunk, offset);
        offset += chunk.length;
      }

      const decoder = new TextDecoder("utf-8");
      const combinedData = decoder.decode(combinedUint8Array);
      // stopButton.value = false;
      if (stopReading.value) {
        replaceText("取消回答", true);
      } else {
        replaceText(combinedData, true);
      }
      stopButton.value = false;
      return;
    }

    // 将接收到的 chunk 存储到数组中
    chunks.push(value);

    // 更新已接收字节数
    bytesReceived += value.length;

    replaceText(value, false);
    // 继续读取下一个数据块
    return reader.read().then(processChunk);
  };
  current.value = false;

  // 当viewType类型为km时，km数据流后面也会返回Thought和Text,需使用kmTemplate模版，需单独标注，；
  currenttypeKM.value = false;

  // 开始读取第一个数据块
  return reader.read().then(processChunk);
}

const replaceText = (value, type) => {
  if (type == true) {
    // type等于true当前流结束
    //取消回答===手动停止
    sending.value = true;
    if (value == "取消回答") {
      setChathistory("finish", value);
      loadingIndex.value = 0;
      allAnswers.value = [];
    } else {
      //判断流是否没有返回最终答案final_answer。
      let last = chathistory.value[chathistory.value.length - 1];
      if (currenttype.value !== "KM") {
        if (last.text == "思考中.." && last.ToolsArray.length <= 0) {
          setChathistory("other", "抱歉，请换个方式或问题提问", "html", true);
        }
      }

      setChathistory("finish");
      allAnswers.value = [];
      loadingIndex.value = 0;
    }
    return;
  }
  const decoder = new TextDecoder("utf-8");
  const combinedDataString = decoder.decode(value);

  const jsonStrings = combinedDataString.match(/data:\s*({.*?})/g);
  const dataLines = combinedDataString.trim().split(/\n+/);

  const jsonDataArray = [];

  dataLines.forEach((line) => {
    const jsonString = line.replace(/^data:\s*/, "");
    try {
      const jsonData = JSON.parse(jsonString);
      jsonDataArray.push(jsonData);
    } catch (error) {
      // console.log(error);
    }
  });
  jsonDataArray.forEach((item, index) => {
    currenttype.value = item.viewType;

    if (currenttype.value == "KM") {
      currenttypeKM.value = true;
      //km类型 currenttype==Text为思考内容 ；currenttype==Thought为最终答案
    }

    if (currenttypeKM.value) {
      kmAnalysis(jsonDataArray);
    } else if (currenttype.value == "Chart") {
      chartAnalysis(jsonDataArray);
    } else {
      if (currenttype.value == "Thought") {
        setChathistory("other", item.text, "html", false);
      } else if (currenttype.value == "Text") {
        //最终答案
        setChathistory("other", item.text, "html", true);
      } else if (currenttype.value == "Data") {
        //柱状图，饼图，表格
        var obj;
        if (
          Array.isArray(item.result.datas) &&
          item.result.datas.length === 0
        ) {
          obj = {
            answer_tools: item.question_str.trim(),
            type: item.chartType,
            raw: item.result,
            prompt: "未查询到符合条件的数据，请换个方式提问以明确查询条件",
          };
        } else {
          obj = {
            answer_tools: item.question_str.trim(),
            type: item.chartType,
            raw: item.result,
          };
        }
        setChathistory("other", obj, "html", false, true, "answerTools");
      } else if (currenttype.value == "Tool") {
        var msg = "";
        if (item.tool_input_str) {
          msg += "策略：" + item.tool_name + "（" + item.tool_input_str + "）";
        }

        if (item.tool_execute_command) {
          if (msg.length > 0) {
            msg += "\n";
          }
          msg += "执行命令：" + item.tool_execute_command;
        }

        if (item.tool_raw_result) {
          if (msg.length > 0) {
            msg += "\n";
          }
          msg += "执行结果：" + "\n" + item.tool_raw_result;
        }
        let obj = {
          answer_tools: msg,
        };
        setChathistory("other", obj, "html", false, false, "answerTools");
      } else if (currenttype.value == "Interaction") {
        //交互
        let obj = {
          interaction_html: item.interaction_html,
        };
        setChathistory("other", obj, "html", false, true);
      }
    }
  });
};

const setChathistory = (sender, text, type, solution, input, answerTools) => {
  // console.log(sender, text, type, solution, input, answerTools);
  //solution====>最终回答，true，false;
  //input ====》交互窗口
  //type====>html
  //answerTools 字段
  //sender == finish 结束当前这个对话；other 对方；self 自己；

  var Toolsarray;

  if (sender == "finish") {
    current.value = true;
    turnanimation.value = false;
    if (
      chathistory.value.length > 0 &&
      text == "取消回答" &&
      chathistory.value[chathistory.value.length - 1].sender == "other"
    ) {
      chathistory.value[chathistory.value.length - 1].text = text;
    }
    if (
      chathistory.value[chathistory.value.length - 1] &&
      chathistory.value[chathistory.value.length - 1].sender == "other"
    ) {
      chathistory.value[chathistory.value.length - 1].showAgain = true;
    }
    return;
  }
  //思考中的动画
  if (!turnanimation.value) {
    turnanimation.value = true;
  }

  if (obsoleteSessionID.value == sessionID.value) {
    if (initialization.value) {
      initialization.value = false;
    }
    // var lastRecord;
    // console.log(deleteIndex.value)
    // if(deleteIndex.value){
    //   console.log()
    //   lastRecord = chathistory.value[deleteIndex.value];
    //   console.log(lastRecord)
    // }else{
    //   lastRecord = chathistory.value[chathistory.value.length - 1]
    // }
    const lastRecord = chathistory.value[chathistory.value.length - 1];

    const shouldAppend =
      lastRecord?.sender == "other" && current.value === false;

    let newData = {};

    if (
      sender == "other" &&
      typeof text === "object" &&
      text !== null &&
      "interaction_html" in text
    ) {
      // 交互窗口
      responseHtml.value = "";
      loadAndExecuteBackendCode(text.interaction_html);
    } else if (answerTools) {
      //【pre】
      if (Object.keys(text).length !== 0) {
        Toolsarray = text;
        text = Toolsarray;
      }
      text = "";
    } else {
    }
    var newArraytext;
    // var allAnswers = [];
    if (sender == "other") {
      var answerText;
      if (currenttype.value !== "KM") {
        answerText = "思考中..";
      }
      // let answerText = "思考中..";

      if (solution && !currenttypeKM.value) {
        // 当solution为true且currenttypeKM.value为false时
        allAnswers.value.push(text); // 将当前text添加到答案列表、
      } else if (solution && currenttypeKM.value) {
        // 当solution为true且currenttypeKM.value为true时
        allAnswers.value.push(text); // 同样添加text到答案列表
        text = "";
      } else if (!solution && currenttypeKM.value) {
        let parts = text.split("_", 2);
        if (parts[0] == "textAnswer") {
          text = parts[1];
        } else if (parts[0] == "finalAnswer") {
          allAnswers.value.push(parts[1]); // 添加解析出的最终答案到列表
          text = "";
        }
      } else {
        if (typeof text === "object" && "interaction_html" in text) {
          text = "";
        } else if (Array.isArray(text)) {
          const concatenatedText = text.reduce(
            (accumulator, item) => accumulator + item,
            ""
          );
          text = concatenatedText;
        }
        const originalArraytext = text.split("\n");
        const insertIndex = chathistory.value.length + 1;
        newArraytext = [
          ...originalArraytext.slice(0, insertIndex),
          "respdata",
          ...originalArraytext.slice(insertIndex),
        ];
      }

      var joinedAnswers = allAnswers.value.join("");
      newData = {
        sender: sender,
        time: getTime(),
        text: joinedAnswers ? joinedAnswers : "思考中..",
        // Arraytext: solution ? [text] : text.split("\n"),
        Arraytext: [text],
        currencyArraytext: 1,
        ToolsArray: [],
        type: "html",
        solution: solution,
        id: generateUUID(),
        currenttype: currenttypeKM.value ? "KM" : currenttype.value,
        showAgain: false,
        projectName:'research'
      };
    } else {
      setChathistory("finish");
      newData = {
        sender: sender,
        time: getTime(),
        text: text,
        id: generateUUID(),
        showAgain: false,
      };
    }
    if (shouldAppend) {
      if (currenttypeKM.value) {
      }
      lastRecord.Arraytext.push(text);
      if (Toolsarray) {
        lastRecord.ToolsArray.push(Toolsarray);
      }

      if (solution) {
        lastRecord.text = joinedAnswers || newData.text;
      }
    } else {
      // 防止删除的信息的问题再次push到聊天记录里
      if (deleteIndex.value && newData.sender == "self") {
        return;
      }
      //删除聊天记录索引，直接指定位置插入
      if (deleteIndex.value && newData.sender == "other") {
        chathistory.value.splice(deleteIndex.value, 0, newData);
        return;
      }

      // 作为新条目追加到 chathistory.value
      chathistory.value.push(newData);
    }

    scroll();
  }
};

const generateUUID = () => {
  let d = new Date().getTime(); // 获取当前时间
  if (
    typeof performance !== "undefined" &&
    typeof performance.now === "function"
  ) {
    d += performance.now(); // 使用高精度时间
  }
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

const loadAndExecuteBackendCode = (html) => {
  responseHtml.value = html.replace(
    /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/i,
    ""
  );
  responseHtmlShow.value = true;
  rHtmlScript.value = html;
};
const scroll = () => {
  nextTick(() => {
    let chatContainer = document.getElementById("dialog_scroll");
    chatContainer.scrollTop = chatContainer.scrollHeight;
  });
};
const reload = (data) => {
  deleteIndex.value = data;
  // let msg = chathistory.value[chathistory.value.length - 2];
  let msg = chathistory.value[data - 1];
  if (Array.isArray(chathistory.value)) {
    if (data >= 0 && data < chathistory.value.length) {
      chathistory.value.splice(data, 1);

      // chathistory.value.splice(data - 1, 2);
    } else {
      console.warn("索引无效，无法删除");
    }
  } else {
    console.error("chathistory不是数组");
  }

  textareaText.value = msg.text;
  doSend();
};

const handleEnterKey = () => {
  doSend();
};
const findIndexWithCondition = (arr, condition) => {
  for (let i = 0; i < arr.length; i++) {
    if (condition(arr[i])) {
      return i;
    }
  }
  return -1;
};

const kmAnalysis = (data) => {
  data.forEach((item, index) => {
    kmAiopsType(item);
  });
};

const kmAiopsType = (item) => {
  if ("docs" in item) {
    //scene_code=kb_aiops;  "docs"字段在思考过程中，answer在思考中
    var metadataValues = item.docs[0].metadata;
    var metadataArray = Object.entries(metadataValues);
    metadataArray = metadataArray
      .map((item) => {
        if (item === "\n```\n") {
          return "\n";
        } else {
          return item;
        }
      })
      .join("");
    let text = "finalAnswer";
    setChathistory("other", text + "_" + metadataArray, "html", false);
  } else {
    if (item.text == "```json" || item.text == "Action:" || item.text == "\n") {
      return;
    } else {
      let text = "textAnswer";
      setChathistory(
        "other",
        text + "_" + item.text.replace(/(?<!\n)\n(?!$)/g, "<br>").trim(),
        "html",
        false
      );
      // setChathistory("other", item.answer.replace(/\n/g, ""), "html", false);
    }
  }
};

const chartAnalysis = (data) => {
  data.forEach((item, index) => {
    chartAiopsType(item);
  });
};
const chartAiopsType = (item) => {
  //最终答案
  if (item.text) {
  }
  if (item.chartEngine == "ECharts") {
  }
  if (item.chartType) {
  }
};
const getscene = () => {
  let url = "/aiops/llm/list_scene";
  axios.get(url).then((res) => {
    sceneArray.value = res.data.result;
    // sceneArray.value.push(res.data.result[0]);
    sceneMsg.value = res.data.result[0];
    scenevalue.value = res.data.result[0].sceneName;
  });
};

const handleChildSubmit = (data) => {
  rHtmlScript.value = "";
  responseHtmlShow.value = false;
};
const interrupt = () => {
  stopReading.value = true;
  disable.value = false;
  stopButton.value = false;
  sending.value = true;
};

const getTime = () => {
  let currentTime = new Date();
  let year = currentTime.getFullYear();
  let month = (currentTime.getMonth() + 1).toString().padStart(2, "0");
  let day = currentTime.getDate().toString().padStart(2, "0");
  let hours = currentTime.getHours().toString().padStart(2, "0");
  let minutes = currentTime.getMinutes().toString().padStart(2, "0");
  let seconds = currentTime.getSeconds().toString().padStart(2, "0");

  let formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return formattedTime;
};
const uuid = () => {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4";
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
  s[8] = s[13] = s[18] = s[23] = "-";

  var uuid = s.join("");
  return uuid;
};

const voice = () => {
  if (voiceText.value == "语音输入") {
    startRecording();
    if (!isMicrophoneAvailable.value) {
      return;
    }
    voiceText.value = "语音输入中...";
  } else if (voiceText.value == "语音录入中...") {
    voiceText.value = "语音转换中...";
    stopRecording();
    // recognition.value.stop()
  }
};

const clearDiaglog = () => {
  doNewConversation()
};

const getToken = () => {
  var start = window.location.href.indexOf("t=");
  if (start==-1) {
    return ""
  }
  start = start + 2
  var end = window.location.href.indexOf("#", start)
  if (end==-1) {
    return ""
  }

  return window.location.href.substr(start, end - start)
}

const sshManage = () => {
  //var host = "http://localhost:5173/"
  var host = "https://10.139.229.81:9443/"
  //获取当前登录用户凭证
  var t = getToken()
  sshSrc.value = host + "?v=noHeader,noSide&t=" + t + "#/SSHList?v1";
  console.log(t)
  console.log(sshSrc.value)
  frameHeight.value = (window.innerHeight * 0.7) + "px"
  //设置iframe路径
  dialogVisble.value = true
};
const startRecording = async () => {
  try {
    await openMicrophone(true); // 强制重新调起录音功能弹窗
    if (!isMicrophoneAvailable.value) {
      return;
    }
    const constraints = {
      audio: {
        sampleRate: 16000,
        channelCount: 1,
        echoCancellation: true,
        noiseSuppression: true,
      },
    };

    // 尝试按指定的采样率和单声道约束获取音频流
    const stream = await navigator.mediaDevices.getUserMedia(constraints);

    var audioContext = new (window.AudioContext || window.webkitAudioContext)();
    sourceNode = audioContext.createMediaStreamSource(stream);
    analyser = audioContext.createAnalyser();
     analyser.fftSize = 2048; 
     dataArray = new Uint8Array(analyser.fftSize);
    sourceNode.connect(analyser);
    mediaRecorder = new MediaRecorder(stream, { mimeType: "audio/webm" });
    mediaRecorder.addEventListener("dataavailable", (event) => {
      console.log(event);
      if (event.data.size > 0) {
        audioChunks.value.push(event.data);
      }
    });

    mediaRecorder.start();
    voiceText.value = "语音录入中...";
    silenceStart = Date.now();
    detectSound();
  } catch (error) {
    console.error("录音失败：", error);
  }
};

const stopRecording = () => {
  if (mediaRecorder && mediaRecorder.state === "recording") {
    mediaRecorder.stop();
    voiceText.value = "语音转换中...";
  }
  mediaRecorder.addEventListener("stop", async (event: BlobEvent) => {
    const fullBlob = new Blob(audioChunks.value, { type: "audio/webm" });
    const arrayBuffer = await new Response(fullBlob).arrayBuffer();

    // 解码音频数据
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

    // 重采样和混音为单声道
    let resampledBuffer;
    if (audioBuffer.sampleRate !== 16000) {
      const offlineCtx = new OfflineAudioContext(1, audioBuffer.length, 16000);
      const src = offlineCtx.createBufferSource();
      src.buffer = audioBuffer;
      src.connect(offlineCtx.destination);
      await src.start(0);
      //等待离线音频上下文渲染完成，然后从renderedBuffer获取channelData
      const renderedBuffer = await offlineCtx.startRendering();
      resampledBuffer = renderedBuffer.getChannelData(0);
      // console.log("重新采样率", offlineCtx.sampleRate);
    } else {
      resampledBuffer = audioBuffer.getChannelData(0);
    }

    // 将PCM数据转换为Int16Array
    const pcmData = new Int16Array(resampledBuffer.length);
    for (let i = 0; i < resampledBuffer.length; i++) {
      pcmData[i] = Math.min(
        Math.max(Math.round(resampledBuffer[i] * 32767), -32768),
        32767
      );
    }

    // 构造WAV文件格式数据
    const wavHeader = constructWavHeader(pcmData.length, 16000, 16);
    const finalArrayBuffer = new ArrayBuffer(
      wavHeader.byteLength + pcmData.byteLength
    );
    const headerView = new DataView(finalArrayBuffer);
    const pcmView = new DataView(finalArrayBuffer, wavHeader.byteLength);
    headerView.setUint8(0, ...wavHeader);

    for (let i = 0; i < pcmData.length; i++) {
      pcmView.setInt16(i * 2, pcmData[i], true);
    }

    // 创建最终的Blob对象
    const finalBlob = new Blob([finalArrayBuffer], { type: "audio/wav" });

    upFile(finalBlob);
    mediaRecorder.stop();
    audioChunks.value = [];
    mediaRecorder = null;
  });
};
const detectSound = () => {
  if (!analyser) return;

  analyser.getByteTimeDomainData(dataArray);
  
  let sum = 0;
  for (let i = 0; i < dataArray.length; i++) {
    sum += Math.abs(dataArray[i] - 128);  // 将数据归一化为正数形式
  }
  
  const average = sum / dataArray.length;

  if (average > 10) {  // 根据平均振幅来判断是否有声音输入，阈值可以调整
    console.log("有声音输入");
    silenceStart = Date.now();
    // voiceText.value = "正在录音...";
  } else {
    console.log("没有检测到声音");
        if (Date.now() - silenceStart > 3000) {
      console.log("持续静音超过 3 秒，停止录音");
      stopRecording();
      return;
    }
  }

  // 持续检测声音
  requestAnimationFrame(detectSound);
};
const openMicrophone = async () => {
  return new Promise((resolve, reject) => {
    navigator.permissions
      .query({ name: "microphone" })
      .then((Status) => {
        if (Status.state == "granted") {
          isMicrophoneAvailable.value = true;
          resolve();
        } else {
          VoicePromptsTetx.value = "需要打开麦克风";
          prompt("需要打开麦克风");
          navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then(() => {
              isMicrophoneAvailable.value = true;
              resolve();
            })
            .catch((error) => {
              prompt("无法访问麦克风");
              console.error("无法访问麦克风:", error);
              reject(error);
            });
        }
      })
      .catch((error) => {
        prompt("检查录音权限失败:" + error);
        console.error("检查录音权限失败:", error);
        reject(error);
      });
  });
};

function constructWavHeader(
  pcmDataSize,
  sampleRate,
  bitDepth,
  numChannels = 1
) {
  const byteRate = (sampleRate * numChannels * bitDepth) / 8;
  const blockAlign = (numChannels * bitDepth) / 8;

  const riffChunk = [0x52, 0x49, 0x46, 0x46];
  const waveFormat = [0x57, 0x41, 0x56, 0x45];
  const fmtChunk = [0x66, 0x6d, 0x74, 0x20];
  const fmtSubchunkSize = 0x10;
  const formatCategory = 0x01;
  const subchunk2ID = [0x64, 0x61, 0x74, 0x61];

  const fileSize = pcmDataSize + 36;
  const subchunk2Size = pcmDataSize;

  const header = [
    ...riffChunk,
    ...byteToArray(fileSize),
    ...waveFormat,
    ...fmtChunk,
    ...byteToArray(fmtSubchunkSize),
    ...byteToArray(formatCategory),
    ...byteToArray(numChannels),
    ...byteToArray(sampleRate),
    ...byteToArray(byteRate),
    ...byteToArray(blockAlign),
    ...byteToArray(bitDepth),
    ...subchunk2ID,
    ...byteToArray(subchunk2Size),
  ];

  return new Uint8Array(header);
}

function byteToArray(number) {
  return [
    number & 0xff,
    (number >> 8) & 0xff,
    (number >> 16) & 0xff,
    (number >> 24) & 0xff,
  ];
}

const upFile = (data) => {
  obsoleteSessionID.value = sessionID.value;
  const formData = new FormData();
  formData.append("file", data, "recording.wav");
  let url = "/speech/asr/";
  axios
    .post(url, formData)
    .then((res) => {
      if (res.data.code == 200) {
        if (obsoleteSessionID.value == sessionID.value) {
          let text = res.data.data[0];
          if (text.length >= 5 && text.slice(-5) === "发送问题。") {
            text = text.substring(0, text.length - 5);
          }
          textareaText.value = text;
        }
      } else {
        prompt("语音转换失败");
      }
      voiceText.value = "语音输入";
    })
    .catch((error) => {
      prompt("语音转换失败");
      audioChunks.value.length = 0;
      voiceText.value = "语音输入";
    });
};

const prompt = (text) => {
  VoicePromptsTetx.value = text;
  ElMessage.error(text);
};

const handleSceneChange = (value) => {
  sceneMsg.value = value;
  scenevalue.value = value.sceneName;
  doNewConversation();
};

const startListening = () => {
  recognition.value = new window.webkitSpeechRecognition() || new window.SpeechRecognition();
  recognition.value.start();
  recognition.value.onstart = function (event) {
    console.log("开始讲话",event);
  };

  // 结束语音识别
  recognition.value.onend = function (event) {
    console.log("结束了.",event);
  };

  // 识别到语音结果
  recognition.value.onresult = function (event) {
    var transcript = event.results[0][0].transcript;
    console.log("识别到的: " + transcript);
  };
  recognition.value.onerror = (event) => {
    console.log("错误了: " + event.error);
                stopRecording();
            recognition.value.stop(); // 停止识别
  }
};
const createWebSocket=()=> {
      var url = "ws://192.168.100.132:10095";

      socket.value = new WebSocket(url);
      // socket.value = new WebSocketConnectMethod(url);

      // 监听连接打开事件
      socket.value.onopen = function (event) {
        console.log("WebSocket连接已打开");
      };


      // 监听连接关闭事件
      socket.value.onclose = function (event) {
        if (event.code !== 1000) {
          createWebSocket();
        }
        console.log("WebSocket连接已关闭代码:", event.code);
        console.log("WebSocket连接已关闭原因:", event.reason);
      };

      // 监听连接错误事件
      socket.value.onerror = function (error) {
        console.error("WebSocket连接发生错误:", error);
        console.log("WebSocket连接状态:", error.target.readyState);
      };
    }

</script>

<style lang="less" scoped>
.large-model-content {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  .websocket-area {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 32px;
    .left-region {
      flex: 0 0 300px;
      height: 100%;
      display: flex;
    }
    .edit-area {
      flex: 1;
      flex-direction: column;
      padding-top: 20px;
      text-align: center;
      height: 100%;
      padding: 0 5%;
      // flex: 1;
      // bottom: 0;
      // left: 50%;
      // // max-width: 100%;
      // padding-top: 20px;
      // position: absolute;
      // text-align: center;
      // transform: translate(-50%);
      // // width: 90%;
      // z-index: 10;
      // height: 100%;
      // display: flex;
      // flex-direction: column;
      .dialog-scroll {
        overflow: auto;
        height: calc(100% - 202px);
        height: 70%;
        background-color: #fff;
        padding: 5px 0;
        margin-top: 10px;
        border-radius: 15px;
        min-height: 150px;
        .initial {
          .greeting {
            font-size: 27px;
            font-weight: 600;
            letter-spacing: 2px;
          }
          .robotpng {
            display: inline-block;
            /* background-image: url("@/assets/img/robot.png"); */
            width: 40px;
            height: 40px;
            margin-bottom: 20px;
          }
          .skill {
            font-size: 25px;
            letter-spacing: 1px;
          }
          .prompt {
            font-size: 14px;
            color: #9a8f8f;
          }
        }
        .chat-history {
          .message {
            padding: 0 10px;
          }
          .message-time {
            margin-bottom: 10px;
            font-size: 13px;
          }
          .other {
            .flow {
              div {
                flex: 1;
              }
              .process {
                padding: 0px 5px;
                .processbgitem {
                  float: right;
                  color: #948f8f;
                  cursor: pointer;
                }
              }
              .processbg {
                background-color: #fff;
                border-radius: 4px;
                font-size: 16px;
                border: 1px solid #9a8c8c26;
              }
              .thinking {
                padding: 20px 7px;
                margin-top: 3px;
              }
              .retract-img {
                float: right;
              }
              .thinkrun {
                display: inline-block;
                animation: textrun 1.5s ease-in-out infinite;
                animation-delay: calc(0.1s * var(--i));
              }
              .turn {
                transform-origin: center;
                animation: spin 1s linear infinite;
              }
              .presty {
                background-color: rgb(245, 245, 245);
                padding: 0 7px;
                white-space: pre-wrap;
                font-family: "Microsoft YaHei";
              }
            }
          }
          .message.other {
            margin-bottom: 10px;
            float: left;
            clear: both;
            width: 98%;
            text-align: left;
            .km_aiopsText {
              .text {
                background-color: #8e9ef0;
                color: #fff;
                margin-left: 30px;
                .operate {
                  text-align: right;
                }
              }
            }
            .flow {
              padding-left: 30px;
            }
            .other.km_aiops {
              .flow {
                padding-left: 0 !important;
                .thinkingtext {
                  color: #3b3636;
                }
              }
            }
          }
          .message.self {
            float: right;
            clear: both;
            width: 98%;
            text-align: right;
            .message-time {
              margin-right: 6px;
            }
            .text {
              margin-right: 18px !important;
              padding: 7px;
              border-radius: 4px;
              margin: 0px 0;
              font-size: 18px;
            }
          }
          .text {
            padding: 7px;
            border-radius: 4px;
            margin: 0px 0;
            font-size: 18px;
          }
          .participants {
            display: inline-block;
            width: 20px;
            height: 20px;
            background-size: cover;
            vertical-align: middle;
            cursor: pointer;
          }
          .other-img {
            margin-right: 10px;
          }
          // .websocket-area .edit-area .chat-history .self-img {
          //   margin-left: 10px;
          // }
        }
      }
      .dialog-scroll.column {
        height: 60%;
      }
      .columnsty {
        width: 100%;
        height: 20%;
        z-index: 999;
        bottom: 13%;
        border-radius: 5px;
        overflow: auto;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
      }
      .stopButton {
        width: 100%;
        height: 37px;
        z-index: 999;
        .stop {
          border-radius: 20px;
          letter-spacing: 1px;
          border: 1px solid #667beb;
          color: #667beb;
        }
        .stop:hover {
          color: #667beb;
          border: 1px solid #667beb;
        }
        .stopimg {
          width: 26px;
          height: 26px;
          margin-right: 5px;
          vertical-align: middle;
        }
      }
      .el-button {
        font-size: 16px;
        font-weight: 600;
        padding: 19px 15px;
      }
      .el-button > span {
        letter-spacing: 1px;
      }
      .edit-box.minedit {
        height: 6%;
      }
      .edit-box {
        margin-top: 90%;
        left: 50%;
        padding-top: 10px;
        // position: absolute;
        text-align: center;
        // transform: translate(-50%);
        width: 100%;
        z-index: 10;
        height: 100px;
        height: 10%;
        margin-top: auto;
        bottom: initial;
        .tab-List {
          display: flex;
          flex-flow: wrap;
          justify-content: flex-start;
        }
        .edit-input {
          background: rgb(245, 245, 245);
          border-radius: 16px;
          flex: 1;
          margin: 0 auto;
          padding: 0 12px 12px 10px;
          position: relative;
          z-index: 2;
          height: 100%;
          .edit-content {
            height: 100%;
            .ant-tabs {
              height: 100%;
              .functionArea {
                position: absolute;
                height: 24px;
                line-height: 24px;
                left: 10px;
                right: 10px;
                top: 10px;
                overflow: hidden;
                display: flex;
                .VoicePackage {
                  margin-left: auto;
                  .voiceText {
                    font-size: 14px;
                    font-weight: 600;
                    cursor: pointer;
                    .voicepng {
                      width: 20px;
                      height: 20px;
                      vertical-align: middle;
                      background-size: cover;
                    }
                  }
                }
                .scene {
                  // background-color: #667beb;
                  // color: #fff;
                  font-size: 14px;
                  font-weight: 600;
                  color: #333;
                  border: none;
                  .custom-select {
                    border: none !important;
                  }
                }
                .scene:hover {
                  border: none;
                }
              }
              .disabled {
                position: absolute;
                left: 14px;
                top: 5px;
              }
              .sendpng {
                position: absolute;
                right: 5px;
                bottom: 5px;
                width: 23px;
                height: 23px;
                cursor: pointer;
              }
              .sendpng.prohibit {
                background-image: url("@/assets/img/prohibit.png");
              }
            }
          }
        }
      }
    }
    .area {
      flex: 1;
      flex-direction: column;
      text-align: center;
      height: 100%;

      .partition {
        width: 100%;
        height: 100%;
      }
    }
  }
}
::-webkit-scrollbar {
  width: 2px;
}

/* 滚动条轨道样式 */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* 滚动条滑块样式 */
::-webkit-scrollbar-thumb {
  background-color: #c9c6c6;
  border-radius: 1px;
}

/* 鼠标悬停在滚动条上时的样式 */
::-webkit-scrollbar-thumb:hover {
  background-color: #c9c6c6;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes textrun {
  0% {
    transform: translateY(0px);
  }
  20% {
    transform: translateY(-2px);
  }
  40%,
  100% {
    transform: translateY(0px);
  }
}

.large-model-cpng {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-size: cover;
  vertical-align: middle;
  margin-right: 4px;
  margin-top: 31px;
}
textarea:focus {
  outline: none;
}
</style>
