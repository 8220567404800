import { createApp } from 'vue'
import "@/styles/index.scss"
import store from "@/store"
import loadComponents from "@/components"
import { loadPlugins } from "@/plugins"
import { loadDirectives } from "@/directives"
import '@/assets/css/style.css'
import App from './App.vue'
import router from '@/router'
import "@/router/permission"
import { setToken } from '@/utils/cache/cookies'

const app = createApp(App)
/** 加载插件 */
loadPlugins(app)
/** 加载全局组件 */
loadComponents(app)
/** 加载自定义指令 */
loadDirectives(app)

function generateUUID() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

setToken('dXNlcjE=.0323ABFA9DBE420DB13B17B5E1BE8AAA')
app.provide('generateUUID', generateUUID);

app.use(store)
app.use(router)
app.mount('#app')
