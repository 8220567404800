import { createRouter } from 'vue-router';
import { history, flatMultiLevelRoutes } from './helper';
import routeSettings from '@/config/route';
import { BasicOpsLayout, BasicItOpsLayout, BasicLlmOpsLayout, BasicLayout ,BlankLayout} from '@/layouts';
import research from '../views/research.vue'
import RouteView from './empty.vue';

/**
 * 常驻路由
 */
export const constantRoutes = [
  // {
  //   path: '/base',
  //   name: 'base',
  //   component: BasicLayout,
  //   children: [
  //   ]
  // },
  {
    path: '/llm_ops',
    name: 'llm_ops',
    component: BasicLlmOpsLayout,
    children: [
    ]
  },
  {
    path: '/data_ops',
    name: 'data_ops',
    component: BasicOpsLayout,
    children: [
    ]
  },
  {
    path: '/it_ops',
    name: 'it_ops',
    component: BasicItOpsLayout,
    children: [
    ]
  },
  { path: '/', name: 'navigation', component: () => import('@/views/navigation/index.vue') },
  { path: '/researchChat', name: 'researchChat', component: research },
  {
    path: '/llm_ops/products',
    name: 'products',
    component: BlankLayout,
    meta: {
      title: '数据产品'
    },
    children: [
      {
        path: 'productsWord',
        name: 'productsWord',
        component: () => import('@/views/products/word/index.vue'),
        meta: {
          title: 'word',
          target: '_blank'  // 在新页面打开
        },
      },
      {
        path: 'productsPpt',
        name: 'productsPpt',
        component: () => import('@/views/products/ppt/index.vue'),
        meta: {
          title: 'ppt',
          target: '_blank'  // 在新页面打开

        },
      },
    ]
  },
];

const sharedRoutes = [
  {
    path: 'dashboard',
    name: 'dashboard',
    component: () => import('@/views/index/index.vue'),
    meta: {
      title: '首页',
      noTagsView: true
    }
  },
  {
    path: 'rich',
    name: 'rich',
    component: RouteView,
    meta: {
      title: '资产',
    },
    children: [
      {
        path: 'management',
        name: 'richManage',
        component: RouteView,
        meta: {
          title: '资产管理',
        },
        children: [{
          path: 'maintenance',
          name: 'richManageMaintenance',
          component: RouteView,
          meta: {
            title: '资产维护'
          },
          children: [{
            path: 'list',
            name: 'richManageMaintenanceList',
            component: () => import('@/views/rich/management/maintenance/index.vue'),
            meta: {
              title: '资产维护'
            },
          },{
            path: 'add',
            name: 'richManageMaintenanceAdd',
            component: () => import('@/views/rich/management/maintenance/add.vue'),
            meta: {
              title: '新建资产',
              type: 'add'
            }
          },{
            path: 'edit',
            name: 'richManageMaintenanceEdit',
            component: () => import('@/views/rich/management/maintenance/add.vue'),
            meta: {
              title: '编辑资产',
              type: 'edit'
            }
          }]
        }, {
          path: 'class',
          name: 'richManageClass',
          component: RouteView,
          meta: {
            title: '分类目录'
          },
          children: [{
            path: 'list',
            name: 'richManageClassList',
            component: () => import('@/views/rich/management/class/index.vue'),
            meta: {
              title: '分类目录'
            },
          }]
        },
        {
          path: 'tag',
          name: 'richManageTag',
          component: () => import('@/views/rich/management/tag/index.vue'),
          meta: {
            title: '资产标签'
          }
        }]
      },
      {
        path: 'mode',
        name: 'richMode',
        component: RouteView,
        meta: {
          title: '资产模型'
        },
        children: [{
          path: 'management',
          name: 'richModemanage',
          component: () => import('@/views/rich/mode/management/index.vue'),
          meta: {
            title: '模型管理'
          }
        }]
      },
      {
        path: 'find',
        name: 'richFind',
        component: RouteView,
        meta: {
          title: '资产发现'
        },
        children: [{
          path: 'rule',
          name: 'richFindRule',
          component: () => import('@/views/rich/find/rule/index.vue'),
          meta: {
            title: '发现规则'
          }
        }, {
          path: 'comfirm',
          name: 'richFindComfirm',
          component: () => import('@/views/rich/find/comfirm/index.vue'),
          meta: {
            title: '资产确认'
          }
        }]
      },
    ]
  },
  {
    path: 'knowledge',
    name: 'knowledge',
    component: RouteView,
    meta: {
      title: '知识'
    },
    children: [
      {
        path: 'classification',
        name: 'classification',
        component: () => import('@/views/Knowledge/classification/index.vue'),
        meta: {
          title: '知识维护管理'
        },
      },
      {
        path: 'classificationEdit',
        name: 'classificationEdit',
        component: () => import('@/views/Knowledge/classification/edit.vue'),
        meta: {
          title: '知识分类目录'
        },
      },
      {
        path: 'retrieval',
        name: 'retrieval',
        component: () => import('@/views/Knowledge/retrieval/index.vue'),
        meta: {
          title: '知识全文检索'
        },
      },
      {
        path: 'label',
        name: 'label',
        component: () => import('@/views/Knowledge/label/index.vue'),
        meta: {
          title: '知识分类标签'
        },
      },
      {
        path: 'dictionary',
        name: 'dictionary',
        component: () => import('@/views/system/dictionary.vue'),
        meta: {
          title: '数据字典'
        },
      },
    ]
  },
 
  {
    path: 'indicators',
    name: 'indicators',
    component: RouteView,
    meta: {
      title: '数据指标'
    },
    children: [
      {
        path: 'indicators',
        name: 'indicatorsDataElements',
        component: () => import('@/views/indicators/dataElements/index.vue'),
        meta: {
          title: '指标数据元素'
        },
      },
      {
        path: 'indicatorsDimension',
        name: 'indicatorsDimension',
        component: () => import('@/views/indicators/dimension/index.vue'),
        meta: {
          title: '维度数据元素'
        },
      },
    ]
  },
  {
    path: 'record',
    name: 'record',
    component: RouteView,
    meta: {
      title: '数据'
    },
    children: [
      {
        path: 'record',
        name: 'recordRetrieval',
        component: () => import('@/views/record/retrieval/index.vue'),
        meta: {
          title: '数据检索'
        },
      },
      {
        path: 'recordModel',
        name: 'recordModel',
        component: RouteView,
        meta: {
          title: '数据模型'
        },
        children: [{
          path: 'recordTarget',
          name: 'recordTarget',
          component: () => import('@/views/record/model/target.vue'),
          meta: {
            title: '数据对象'
          },
        },{
          path: '/recorLabels',
          name: 'recorLabels',
          component: () => import('@/views/record/model/objectlabels.vue'),
          meta: {
            title: '对象标签'
          },
        },]
      },
      {
        path: 'recordAdministration',
        name: 'recordAdministration',
        component: RouteView,
        meta: {
          title: '数据管理'
        },
        children: [{
          path: 'recordMaintenance',
          name: 'recordMaintenance',
          component: () => import('@/views/record/administration/maintenance.vue'),
          meta: {
            title: '数据维护'
          },
        },{
          path: 'recorRelationship',
          name: 'recorRelationship',
          component: () => import('@/views/record/administration/respect.vue'),
          meta: {
            title: '关系维护'
          },
        },]
      },
      {
        path: 'recorData',
        name: 'recorData',
        component: RouteView,
        meta: {
          title: '数据权限'
        },
        children: [{
          path: 'recorCharacter',
          name: 'recorCharacter',
          component: () => import('@/views/record/dataPermissions/character.vue'),
          meta: {
            title: '对象权限'
          },
        },
        {
          path: 'recorField',
          name: 'recorField',
          component: () => import('@/views/record/dataPermissions/field.vue'),
          meta: {
            title: '字段权限'
          },
        },]
      },
    ]
  },
  {
    path: 'intelligent',
    name: 'intelligent',
    component: RouteView,
    meta: {
      title: '智能'
    },
    children: [
      {
        path: 'perception',
        name: 'perception',
        component: RouteView,
        meta: {
          title: '智能感知'
        },
        children:[{
          path: 'strategy',
          name: 'strategy',
          component: RouteView,
          meta: {
            title: '采集策略'
          },
          children: [{
            path: 'asset',
            name: 'asset',
            component: () => import('@/views/intelligent/perception/strategy/asset.vue'),
            meta: {
              title: '资产测点管理'
            }
          }, {
            path: 'collectionStrategy',
            name: 'collectionStrategy',
            component: () => import('@/views/intelligent/perception/strategy/collectionStrategy.vue'),
            meta: {
              title: '采集策略管理'
            }
          },]
        }]
        // path: '/perception',
        // name: 'perception',
        // component: () => import('@/views/Knowledge/classification/index.vue'),
        // meta: {
        //   title: '知识分类目录'
        // },
      },
      {
        path: 'cognition',
        name: 'cognition',
        component: RouteView,
        meta: {
          title: '智能认知'
        },
        children:[ ]
      },
      {
        path: 'decision',
        name: 'decision',
        component: RouteView,
        meta: {
          title: '智能决策'
        },
        children:[{
          path: 'logic',
          name: 'decisionLogic',
          component: RouteView,
          meta: {
            title: '决策逻辑'
          },
          children: [{
            path: 'strategy',
            name: 'logicStrategy',
            component: () => import('@/views/intelligent/decision/logic/strategy/index.vue'),
            meta: {
              title: '决策策略管理'
            }
          }]
        }]
      },


      {
        path: 'classificationEdit',
        name: 'classificationEdit',
        component: () => import('@/views/Knowledge/classification/edit.vue'),
        meta: {
          title: '知识分类目录'
        },
      },
      {
        path: 'retrieval',
        name: 'retrieval',
        component: () => import('@/views/Knowledge/retrieval/index.vue'),
        meta: {
          title: '知识全文检索'
        },
      },
      {
        path: 'label',
        name: 'label',
        component: () => import('@/views/Knowledge/label/index.vue'),
        meta: {
          title: '知识分类标签'
        },
      },
      {
        path: 'dictionary',
        name: 'dictionary',
        component: () => import('@/views/system/dictionary.vue'),
        meta: {
          title: '数据字典'
        },
      },
    ]
  },
];

function getRouterTree(routes, arr, parentId) {
  try {
    if (!routes.length) return []
    routes.forEach((item) => {
      const child = {
        ...item,
        name: parentId + item.name,
        children: []
      }
      if (item.children?.length) {
        child.children = getRouterTree(
          item.children,
          [],
          parentId
        )
      } else {
        delete child.children
      }
      arr.push(child)
    })
    return arr
  } catch (e) {
    console.log(e)
    return []
  }
}

const mutiEntryRouters = ['base', 'llm_ops', 'data_ops', 'it_ops']
constantRoutes.forEach(route => {
  if (route.children && mutiEntryRouters.includes(route.name)) {
    route.children = getRouterTree(sharedRoutes, [], route.name)
  }
});
// console.log('constantRoutes_______', constantRoutes)
export const dynamicRoutes=[];
const router = createRouter({
  history,
  routes: constantRoutes
});

/** 重置路由 */
export function resetRouter() {
  try {
    router.getRoutes().forEach((route) => {
      const { name, meta } = route;
      if (name && meta.roles?.length) {
        router.hasRoute(name) && router.removeRoute(name);
      }
    });
  } catch {
    window.location.reload();
  }
}

export default router;
