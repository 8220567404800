<template>
  <div class="message other" :id="messageData.id">
    <!-- <img :src="robotImg" alt="" class="other-img participants" /> -->
    <img class="logopng" src="~@/assets/img/layout/logo.png" alt="" />
    <div class="content-Area">
      <div
        class="text"
        v-if="messageData.ToolsArray && messageData.ToolsArray.length == 0"
      >
        <div
          v-if="
            messageData.text == '思考中..' &&
            messageData.ToolsArray &&
            messageData.ToolsArray.length == 0
          "
          class="text-area"
        >
          <span style="--i: 1" :class="{ thinkrun: turnanimation }">思</span>
          <span style="--i: 2" :class="{ thinkrun: turnanimation }">考</span>
          <span style="--i: 3" :class="{ thinkrun: turnanimation }">中</span>
          <span style="--i: 4" :class="{ thinkrun: turnanimation }">.</span>
          <span style="--i: 5" :class="{ thinkrun: turnanimation }">.</span>
          <span style="--i: 6" :class="{ thinkrun: turnanimation }">.</span>
        </div>
        <div class="text-area" v-else>
          <div v-html="formattedText(messageData.text)"></div>
        </div>
      </div>
      <div class="flow">
        <div
          v-if="messageData.ToolsArray"
          v-for="(itemtools, toolsindex) in messageData.ToolsArray"
          :key="toolsindex"
        >
          <div>
            <pre class="presty">{{ itemtools.answer_tools.trim() }}</pre>
            <pre class="presty" v-if="itemtools.prompt">{{itemtools.prompt}}</pre>
            <div class="filterCriteria">
              <!-- <div class="criteria" v-if="jsonData">
                <div class="source tags">
                  <span class="label">数据来源:</span>
                  <span class="condition"
                    >{{ jsonData.source
                    }}<span
                      class="dele"
                      @click="deletag('source', jsonData.source)"
                      >x</span
                    ></span
                  >
                </div>
                <div class="content tags">
                  <span class="label">数据内容:</span>
                  <span
                    class="condition"
                    v-for="(item, index) in jsonData.fields"
                    :key="index"
                    >{{ item
                    }}<span class="dele" @click="deletag('fields', item)"
                      >x</span
                    ></span
                  >
                  <span class="add" @click="addtag('fields', item, '数据内容')"
                    >+ New Tag</span
                  >
                </div>
                <div class="grouping tags">
                  <span class="label">分组条件:</span>
                  <span
                    class="condition"
                    v-for="(item, index) in jsonData.groupBy"
                    :key="index"
                    >{{ item
                    }}<span class="dele" @click="deletag('groupBy', item)"
                      >x</span
                    ></span
                  >
                  <span class="add" @click="addtag('groupBy', item, '分组条件')"
                    >+ New Tag</span
                  >
                </div>
                <div class="having tags">
                  <span class="label">分组过滤条件:</span>
                  <span
                    class="condition"
                    v-for="(item, index) in jsonData.having"
                    :key="index"
                    >{{ item
                    }}<span class="dele" @click="deletag('having', item)"
                      >x</span
                    ></span
                  >
                  <span
                    class="add"
                    @click="addtag('having', item, '分组过滤条件')"
                    >+ New Tag</span
                  >
                </div>
                <div class="filter tags">
                  <span class="label">过滤条件:</span>
                  <span
                    class="condition"
                    v-for="(item, index) in jsonData.condition"
                    :key="index"
                    >{{ item
                    }}<span class="dele" @click="deletag('condition', item)"
                      >x</span
                    ></span
                  >
                  <span
                    class="add"
                    @click="addtag('condition', item, '过滤条件')"
                    >+ New Tag</span
                  >
                </div>
                <div class="sort tags">
                  <span class="label">排序:</span>
                  <span
                    class="condition"
                    v-for="(item, index) in jsonData.orderBy"
                    :key="index"
                    >{{ item
                    }}<span class="dele" @click="deletag('orderBy', item)"
                      >x</span
                    ></span
                  >
                  <span class="add" @click="addtag('orderBy', item, '排序')"
                    >+ New Tag</span
                  >
                </div>
              </div> -->
              <div class="chartConditions">
                <div class="left">
                  <img
                    src="@/assets/img/line.png"
                    @click="switchChart('LineChart')"
                    alt=""
                  />
                  <img
                    src="@/assets/img/ybar.png"
                    @click="switchChart('ybarchart')"
                    alt=""
                  />
                  <img
                    src="@/assets/img/xbar.png"
                    @click="switchChart('xbarchart')"
                    alt=""
                  />
                  <img
                    src="@/assets/img/pie.png"
                    @click="switchChart('PieChart')"
                    alt=""
                  />
                  <img
                    src="@/assets/img/table.png"
                    @click="switchChart('Table')"
                    alt=""
                  />
                </div>
                <div class="right">
                  <!-- <el-button type="danger" @click="refresh">刷新</el-button> -->
                  <el-dropdown class="spacing">
                    <el-button type="danger"
                      >操作<svg-icon
                        class="avter"
                        name="knowledge-drop-down"
                        size="12"
                      ></svg-icon
                    ></el-button>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                          v-for="(item, index) in establishList"
                          @click="doestablish(item)"
                          >{{ item.name }}</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
              </div>
            </div>
            <Barchart
              v-if="
                itemtools.type == 'Histogram' && itemtools.raw.result.datas.length > 0
              "
              :BarchartData="itemtools.raw.result"
              :barchartDownload="barchartDownload"
            />
            <Barchartxy
              v-if="
                itemtools.type == 'xbarchart' && itemtools.raw.result.datas.length > 0
              "
              :BarchartxyData="itemtools.raw.result"
              :barchartxyDownload="barchartDownload"
            />
            <Barchartxy
              v-if="
                itemtools.type == 'ybarchart' && itemtools.raw.result.datas.length > 0
              "
              :BarchartxyData="itemtools.raw.result"
              :barchartxyDownload="barchartDownload"
            />
            <Piechart
              v-if="
                itemtools.type == 'PieChart' && itemtools.raw.result.datas.length > 0
              "
              :PiechartData="itemtools.raw.result"
              :pieChartDownload="pieChartDownload"
            />
            <Tablec
              v-if="itemtools.type == 'Table' && itemtools.raw.result.datas.length > 0"
              :tableData="itemtools.raw.result"
            />
            <CateGory
              v-if="
                itemtools.type == 'LineChart' && itemtools.raw.result.datas.length > 0
              "
              :cateGoryData="itemtools.raw.result"
              :lineChartDownload="lineChartDownload"
            />
            <Mapchart
              v-if="itemtools.type == 'Map' && itemtools.raw.result.datas.length > 0"
              :MapData="itemtools.raw.result"
            ></Mapchart>
            <!-- <mapt v-if="itemtools.type == 'Map' && itemtools.raw.datas.length > 0"
            :MapData="itemtools.raw"></mapt> -->
          </div>
        </div>
        <div class="process course" v-show="!process">
          <div class="left-tool">
            <span
              class="left-processbgitem"
              v-show="turnanimation && historyindex == loadingindex"
              ><svg-icon class="img" name="stop-generating" size="14"></svg-icon
              ><span class="buttonText" @click="stopGenerating"
                >停止生成</span
              ></span
            >
            <span
              class="left-processbgitem"
              v-show="lshowAgain && historyindex == loadingindex"
              ><svg-icon class="img" name="redo" size="14"></svg-icon
              ><span class="buttonText" @click="reload(messageData)"
                >重新生成</span
              ></span
            >
            <span class="left-processbgitem"
              ><svg-icon class="img" name="share" size="14"></svg-icon
              ><span class="buttonText">分享</span></span
            >
            <span class="left-processbgitem"
              ><svg-icon class="img" name="copy" size="14"></svg-icon
              ><span class="buttonText">复制</span></span
            >
          </div>
          <span class="processbgitem" @click="openfunction(messageData)">
            思考过程
          </span>
        </div>
        <div v-show="process" class="processbg thinking">
          <div
            v-for="(textItem, indexs) in processedTextItems"
            :key="indexs"
            class="thinkingtext"
          >
            <template v-if="textItem === 'respdata'">
              <div v-html="responseHtml"></div>
            </template>
            <template v-else>
              <div v-html="textItem"></div>
              <!-- <div v-html="textItem.replace(/\n/g, '<br>')"></div> -->
              <!-- <div v-html="textItem.replace(/\n/g, '<br>').trim()"></div> -->
            </template>
          </div>
          <img
            :src="retractImg"
            alt=""
            class="retract-img participants"
            @click="process = false"
          />
        </div>
        <div v-if="summary" class="summary">
          <div>对于 {{summarykey}}：</div>
          <div>1. 最大值：{{summary[summarykey].max_value}}，出现在<span v-for="(item,index) in summary[summarykey].max_dimension" :key="index">{{item}}</span></div>
          <div>2. 最小值：{{summary[summarykey].min_value}}，出现在<span v-for="(item,index) in summary[summarykey].min_dimension" :key="index">{{item}}</span></div>
          <div>3. 中位数：{{summary[summarykey].median_value}} </div>
          <div>4. 总和：{{summary[summarykey].total_value}}</div>
        </div>
      </div>
    </div>
    <el-dialog v-model="dialogFormVisible" :title="addTagTitle" width="500">
      <el-form :model="form">
        <el-form-item label="" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click="sure"> 确定 </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import Tablec from "@/components/Tablec.vue";
import Barchart from "@/components/Barchart.vue";
import Barchartxy from "@/components/Barchartxy.vue";
// import Barcharty from "@/components/Barcharty.vue";
import Piechart from "@/components/Piechart.vue";
import CateGory from "@/components/CateGory.vue";
import Mapchart from "@/components/Mapchart.vue";
import mapt from "@/components/mapt.vue";

import robotImg from "@/assets/img/robot.png";
import loadingImg from "@/assets/img/loading.png";
import retractImg from "@/assets/img/retract.png";
import retryImg from "@/assets/img/retry.png";
import { dialogueScreen } from "@/api/indexAxios.js";
import { ElMessage } from "element-plus";
export default {
  props: {
    messageData: {
      type: Object,
      required: true,
    },
    messagekey: {
      type: String,
      required: true,
    },
    messageAnmation: {
      type: Boolean,
    },
    loadingvalue: {
      type: String,
    },
    historyList: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      thinking: "",
      robotImg: robotImg,
      loadingImg: loadingImg,
      retractImg: retractImg,
      retryImg: retryImg,
      turnanimation: true,
      process: false, //过程显示
      historyindex: "",
      loadingindex: "",
      lshowAgain: false,
      establishList: [{ name: "下载图片" }, { name: "复制条件" }],
      barchartDownload: false, //bar下载
      pieChartDownload: false,
      lineChartDownload: false,
      jsonData: null,
      addType: null,
      addTagTitle: null,
      form: {
        name: "",
      },
      dialogFormVisible: false,
      summary:null,
      summarykey:null
    };
  },
  components: {
    Tablec,
    Barchart,
    Barchartxy,
    Piechart,
    CateGory,
    Mapchart,
    mapt,
  },
  computed: {
    processedTextItems() {
      const result = [];
      let previousEndsWithNewline = false;
      console.log(this.messageData.Arraytext)
      this.messageData.Arraytext.forEach((item) => {
        const originalItem = item;
        let cleanedItem = item
          .replace(/\n/g, "<br>") // 将换行符替换为 <br>
          .replace(/ /g, "&nbsp;"); // 将空格替换为 &nbsp;

        const currentStartsWithNewline = /^\n/.test(originalItem);
        if (previousEndsWithNewline || currentStartsWithNewline) {
          result.push(cleanedItem);
        } else {
          if (result.length > 0) {
            result[result.length - 1] += cleanedItem;
          } else {
            result.push(cleanedItem);
          }
        }
        previousEndsWithNewline =
          cleanedItem.endsWith("<br>") || originalItem.endsWith("\n");
      });

      return result;
    },
  },
  created() {},
  mounted() {},
  watch: {
    messageData: {
      deep: true,
      handler(newData) {
        if (newData.ToolsArray && newData.ToolsArray.length > 0) {
          this.jsonData = newData.ToolsArray[0].raw.result.json;
          this.summary = newData.ToolsArray[0].raw.analysis_results;
          this.summarykey = Object.keys(this.summary)[0];
          // console.log(Object.keys(this.summary)[0])
          // console.log(key,this.summary)
          //           console.log(this.summary[key[0]])
          // console.log(this.summary.key[0])
        }
      },
    },
    messagekey(newData) {
      this.historyindex = newData.split("_")[0];
    },
    messageAnmation: {
      deep: true,
      handler(newData) {
        this.turnanimation = newData;
      },
    },
    loadingvalue: {
      deep: true,
      handler(newData) {
        this.loadingindex = newData;
      },
    },
    historyList: {
      deep: true,
      handler(newData) {
        var index = newData.findIndex(
          (newData) => newData.id == this.messageData.id
        );
        if (index + 1 == newData.length) {
          this.lshowAgain = true;
        } else {
          this.lshowAgain = false;
        }
      },
    },
  },
  methods: {
    openfunction(data) {
      this.process = true;
      let sharedData = {
        launch: "",
      };
      this.$emit("updateShare", sharedData);
    },
    formattedTextarray(data) {
      let text = "";
      // data.forEach(item => {
      //   text+=item.replace(/\n/g, '<br>').trim();
      // });
      // return text
      data
        .filter((item) => item !== "respdata")
        .forEach((item) => {
          text += item.replace(/\n/g, "<br>").trim();
        });
      return text;
    },
    formattedText(text) {
      let trimmedText = text.replace(/\n/g, "<br>").trim();
      return trimmedText;
    },
    reload(data) {
      this.$emit("reload", data);
    },
    stopGenerating() {
      this.$emit("stopGenerating", true);
    },
    doestablish(d) {
      const type = this.messageData.ToolsArray[0].type;
      if (d.name == "下载图片") {
        if (type == "Histogram" || type == "ybarchart" || type == "xbarchart") {
          this.barchartDownload = true;
          setTimeout(() => {
            this.barchartDownload = false;
          }, 100);
        } else if (type == "PieChart") {
          this.pieChartDownload = true;
          setTimeout(() => {
            this.pieChartDownload = false;
          }, 100);
        } else if (type == "LineChart") {
          this.lineChartDownload = true;
          setTimeout(() => {
            this.lineChartDownload = false;
          }, 100);
        } else {
          return;
        }
      } else if (d.name == "复制条件") {
        this.copyToClipboard();
      }
    },
    switchChart(d) {
      this.messageData.ToolsArray[0].type = d;
      if (d == "xbarchart") {
        this.messageData.ToolsArray[0].raw.result.axisType = "y";
      } else if (d == "ybarchart") {
        this.messageData.ToolsArray[0].raw.result.axisType = "x";
      }
    },
    deletag(type, data) {
      if (type == "source") {
        this.jsonData.source = "";
      } else if (Array.isArray(this.jsonData[type])) {
        const index = this.jsonData[type].indexOf(data);
        if (index > -1) {
          this.jsonData[type] = this.jsonData[type].filter(
            (item) => item !== data
          );
        }
      }
    },
    addtag(type, data, title) {
      this.addTagTitle = title;
      this.dialogFormVisible = true;
      this.addType = type;
    },
    sure() {
      this.dialogFormVisible = false;
      if (this.form.name) {
        this.jsonData[this.addType].push(this.form.name);
        this.form.name = "";
      }
    },
    refresh() {
      console.log(this.messageData.ToolsArray);
      dialogueScreen(this.jsonData).then((res) => {
        const { columns, datas, analysisResult, humanMessage } = res.result;
        this.messageData.ToolsArray[0].raw.result = {
          ...this.messageData.ToolsArray[0].raw.result,
          columns,
          datas,
          // analysisResult,
          humanMessage,
        };
      });
    },
    copyToClipboard() {
      let data = JSON.stringify(this.jsonData);
      if (data == null) {
        ElMessage({
          message: "暂无条件可复制",
          type: "warn",
        });
        return;
      }
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard
          .writeText(data)
          .then(() => {
            ElMessage({
              message: "已成功复制到剪贴板！",
              type: "success",
            });
          })
          .catch((err) => {
            console.error("复制失败:", err);
          });
      } else {
        let textArea = document.createElement("textarea");
        textArea.value = data;
        textArea.style.position = "fixed"; // 避免影响页面布局
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          let successful = document.execCommand("copy");
          let msg = successful ? "已成功复制到剪贴板！" : "复制失败";
          let type = successful ? "success" : "warn";
          ElMessage({
            message: msg,
            type: type,
          });
        } catch (err) {
          console.error("复制失败:", err);
        }
        document.body.removeChild(textArea);
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "@/assets/css/common.less";
.participants {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: cover;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
}
.reload {
  background-size: cover;
  vertical-align: middle;
  cursor: pointer;
  display: inline-block;
  float: right;
  margin-left: 5px;
  margin-top: 3px;
  width: 18px;
  height: 18px;
}
.message {
  width: 100%;
  height: 100%;
  text-align: left;
  padding: 0 10px;
}
.message-time {
  margin-left: 6px;
  margin-bottom: 10px;
  font-size: 13px;
}
.other {
  display: flex;
  .logopng {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  .content-Area {
    border-radius: 4px;
    flex: 1;
    background-color: #fff;
    padding: 10px 20px;
    font-family: PingFangSC-Regular;
    .text {
      letter-spacing: 1px;
      border-radius: 4px;
      font-weight: 400;
      font-size: 14px;

      .text-area {
        background: @text-bgcolor;
        padding: 10px 20px;
        color: #1f1f1f;
        border-radius: 4px;
      }
    }
  }

  .thinkrun {
    display: inline-block;
    animation: textrun 1.5s ease-in-out infinite;
    animation-delay: calc(0.1s * var(--i));
  }
  .flow {
    margin-top: 20px;
    // overflow: hidden;
    > div {
      flex: 1;
    }
    .process {
      margin-top: 20px;
      padding: 0px 5px;
      overflow: hidden;
      .left-tool {
        float: left;
        .left-processbgitem {
          cursor: pointer;
          display: flex;
          align-items: center;
          background: #fff8f3;
          font-size: 14px;
          padding: 6px 20px;
          color: #ff7957;
          .buttonText {
            font-size: 14px;
            margin-left: 4px;
          }
          float: left;
          border-left: 1px solid FFCDC0;
        }
      }
      .processbgitem {
        background: #fff8f3;
        border-radius: 4px;
        float: right;
        cursor: pointer;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 14px;
        color: #ff7957;
        letter-spacing: 0;
        text-align: center;
        padding: 6px 20px;
      }
    }
    .processbg {
      background-color: #fff;
      border-radius: 4px;
      font-size: 16px;
      border: 1px solid #9a8c8c26;
    }
    .thinking {
      padding: 20px 7px;
      margin-top: 3px;
      word-break: break-word;
      white-space: pre-wrap;
    }
    .retract-img {
      float: right;
    }
    .turn {
      transform-origin: center;
      animation: spin 1s linear infinite;
    }
    .presty {
      background-color: @text-bgcolor;
      padding: 10px 20px;
      white-space: pre-wrap;
      margin: 0 0 7px 0;
      border-radius: 4px;
      font-size: 14px;
      color: #1f1f1f;
    }
    .filterCriteria {
      display: flex;
      flex-direction: column;
      .chartConditions {
        display: flex;
        height: 40px;
        justify-content: space-between;
        .left {
          img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            cursor: pointer;
            vertical-align: middle;
          }
          img:nth-child(2) {
            width: 17px;
            height: 16px;
          }
        }
        .right {
          width: 20%;
          text-align: right;
        }
      }
    }
  }

  .summary{
    margin-top: 6px;
    padding: 6px 0;
    border-top: 1px solid #8C94B5;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes textrun {
  0% {
    transform: translateY(0px);
  }
  20% {
    transform: translateY(-2px);
  }
  40%,
  100% {
    transform: translateY(0px);
  }
}
.thinkingtext {
  word-wrap: break-word !important;
  white-space: pre-wrap !important; /* 保留空白符并且换行 */
  max-width: 100% !important; /* 确保最大宽度不超过父元素 */
  overflow-wrap: break-word !important; /* 强制长单词换行 */
}

.criteria {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-bottom: 10px;
}

.criteria .tags {
  padding: 10px;
  border-radius: 4px;
  box-sizing: border-box;
}

.criteria .tags .label {
  font-weight: bold;
  margin-right: 5px;
}

.criteria .tags .condition {
  background-color: #fff8f3;
  color: #ff7957;
  padding: 6px 20px;
  margin-right: 5px;
  cursor: pointer;
}
.tags .condition .dele {
  margin-left: 12px;
}

.criteria .tags .add {
  cursor: pointer;
  color: #ff7957;
  border: 1px solid #fff8f3;
  padding: 3px;
  margin-left: 10px;
}
</style>
